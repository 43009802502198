import React, { useEffect, useState } from "react";
import Pet from "./components/Pet.jsx";

import { db } from "./firebase/init.js";
import { getDocs, collection, addDoc, query, where, } from "firebase/firestore";


export const generalGenres = [
  "Ambient",
  "Pop",
  "Hip Hop",
  "Rock",
  "Indie Pop",
  "Alternative Rock",
  "Electronic",
  "R&B",
  "Jazz",
  "Country",
  "Blues",
  "Classical",
  "Reggae",
  "Punk",
  "Folk",
  "Metal",
  "Soul",
  "Game",
  "Rap",
  "Funk",
  "Disco",
  "Psychedelic",
  "Indie Rock",
  "Grunge",
  "Tropical",
  "Chill",
  "Emo",
  "Dance",
];


const allGenres = [
  { id: 2, name: "A Cappella", alias: "Other" },
  { id: 3, name: "Abstract", alias: "Ambient" },
  { id: 4, name: "Abstract Beats", alias: "Dance" },
  { id: 5, name: "Abstract Hip Hop", alias: "Hip Hop" },
  { id: 6, name: "Abstract Idm", alias: "Electronic" },
  { id: 7, name: "Abstractro", alias: "Electronic" },
  { id: 8, name: "Accordion", alias: "Folk" },
  { id: 9, name: "Acid House", alias: "Dance" },
  { id: 10, name: "Acid Jazz", alias: "Jazz" },
  { id: 11, name: "Acid Techno", alias: "Electronic" },
  { id: 12, name: "Acousmatic", alias: "Dance" },
  { id: 13, name: "Acoustic Blues", alias: "Blues" },
  { id: 14, name: "Acoustic Pop", alias: "Pop" },
  { id: 15, name: "Adult Standards", alias: "Pop" },
  { id: 16, name: "African Percussion", alias: "Other" },
  { id: 17, name: "African Rock", alias: "Rock" },
  { id: 18, name: "Afrikaans", alias: "Pop" },
  { id: 19, name: "Afrobeat", alias: "Dance" },
  { id: 20, name: "Afrobeats", alias: "Dance" },
  { id: 21, name: "Aggrotech", alias: "Electronic" },
  { id: 22, name: "Albanian Pop", alias: "Pop" },
  { id: 23, name: "Album Rock", alias: "Rock" },
  { id: 24, name: "Albuquerque Indie", alias: "Indie Rock" },
  { id: 25, name: "Alternative Americana", alias: "Country" },
  { id: 26, name: "Alternative Country", alias: "Country" },
  { id: 27, name: "Alternative Dance", alias: "Dance" },
  { id: 28, name: "Alternative Emo", alias: "Emo" },
  { id: 29, name: "Alternative Hardcore", alias: "Punk" },
  { id: 30, name: "Alternative Hip Hop", alias: "Hip Hop" },
  { id: 31, name: "Alternative Metal", alias: "Metal" },
  { id: 32, name: "Alternative Metalcore", alias: "Metal" },
  { id: 33, name: "Alternative New Age", alias: "Ambient" },
  { id: 34, name: "Alternative Pop", alias: "Pop" },
  { id: 35, name: "Alternative Pop Rock", alias: "Alternative Pop" },
  { id: 36, name: "Alternative R&b", alias: "R&B" },
  { id: 37, name: "Alternative Rock", alias: "Alternative Rock" },
  { id: 38, name: "Alternative Roots Rock", alias: "Alternative Rock" },
  { id: 39, name: "Ambeat", alias: "Electronic" },
  { id: 40, name: "Ambient", alias: "Ambient" },
  { id: 41, name: "Ambient Dub Techno", alias: "Electronic" },
  { id: 42, name: "Ambient Fusion", alias: "Ambient" },
  { id: 43, name: "Ambient Idm", alias: "Electronic" },
  { id: 44, name: "Ambient Psychill", alias: "Electronic" },
  { id: 45, name: "Ambient Trance", alias: "Electronic" },
  { id: 46, name: "Anarcho-punk", alias: "Punk" },
  { id: 47, name: "Andean", alias: "Other" },
  { id: 48, name: "Anime", alias: "Game" },
  { id: 49, name: "Anime Score", alias: "Game" },
  { id: 50, name: "Anti-folk", alias: "Folk" },
  { id: 51, name: "Antiviral Pop", alias: "Pop" },
  { id: 52, name: "Appalachian Folk", alias: "Folk" },
  { id: 53, name: "Arab Folk", alias: "Folk" },
  { id: 54, name: "Arab Pop", alias: "Pop" },
  { id: 55, name: "Arabesk", alias: "Other" },
  { id: 56, name: "Argentine Indie", alias: "Indie Rock" },
  { id: 57, name: "Argentine Reggae", alias: "Reggae" },
  { id: 58, name: "Argentine Rock", alias: "Rock" },
  { id: 59, name: "Armenian Folk", alias: "Folk" },
  { id: 60, name: "Art Rock", alias: "Rock" },
  { id: 61, name: "Athens Indie", alias: "Indie Rock" },
  { id: 62, name: "Atmospheric Black Metal", alias: "Metal" },
  { id: 63, name: "Atmospheric Post Rock", alias: "Indie Rock" },
  { id: 64, name: "Atmospheric Post-metal", alias: "Metal" },
  { id: 65, name: "Aussietronica", alias: "Dance" },
  { id: 66, name: "Austindie", alias: "Indie Rock" },
  { id: 67, name: "Australian Alternative Rock", alias: "Rock" },
  { id: 68, name: "Australian Country", alias: "Country" },
  { id: 69, name: "Australian Dance", alias: "Dance" },
  { id: 70, name: "Australian Hip Hop", alias: "Hip Hop" },
  { id: 71, name: "Australian Indie", alias: "Indie Rock" },
  { id: 72, name: "Australian Pop", alias: "Pop" },
  { id: 73, name: "Austrian Hip Hop", alias: "Hip Hop" },
  { id: 74, name: "Austropop", alias: "Pop" },
  { id: 75, name: "Avant-garde", alias: "Other" },
  { id: 76, name: "Avant-garde Jazz", alias: "Jazz" },
  { id: 77, name: "Avantgarde Metal", alias: "Metal" },
  { id: 78, name: "Axe", alias: "Dance" },
  { id: 79, name: "Azonto", alias: "Dance" },
  { id: 80, name: "Bachata", alias: "Other" },
  { id: 81, name: "Baile Funk", alias: "Dance" },
  { id: 82, name: "Balearic", alias: "Dance" },
  { id: 83, name: "Balkan Brass", alias: "Jazz" },
  { id: 84, name: "Banda", alias: "Other" },
  { id: 85, name: "Bangla", alias: "Other" },
  { id: 86, name: "Barbershop", alias: "Other" },
  { id: 87, name: "Barnemusikk", alias: "Folk" },
  { id: 88, name: "Barnmusik", alias: "Folk" },
  { id: 89, name: "Baroque", alias: "Classical" },
  { id: 90, name: "Baroque Ensemble", alias: "Classical" },
  { id: 91, name: "Basque Rock", alias: "Rock" },
  { id: 92, name: "Bass Music", alias: "Electronic" },
  { id: 93, name: "Bass Trip", alias: "Electronic" },
  { id: 94, name: "Bassline", alias: "Dance" },
  { id: 95, name: "Bay Area Hip Hop", alias: "Hip Hop" },
  { id: 96, name: "Beach Music", alias: "Pop" },
  { id: 97, name: "Beatdown", alias: "Metal" },
  { id: 98, name: "Beats & Rhymes", alias: "Hip Hop" },
  { id: 99, name: "Bebop", alias: "Jazz" },
  { id: 100, name: "Belgian Indie", alias: "Indie Rock" },
  { id: 101, name: "Belgian Rock", alias: "Rock" },
  { id: 102, name: "Belly Dance", alias: "Other" },
  { id: 103, name: "Belorush", alias: "Other" },
  { id: 104, name: "Bemani", alias: "Other" },
  { id: 105, name: "Benga", alias: "Other" },
  { id: 106, name: "Bhangra", alias: "Other" },
  { id: 107, name: "Big Band", alias: "Jazz" },
  { id: 108, name: "Big Beat", alias: "Dance" },
  { id: 109, name: "Big Room", alias: "Dance" },
  { id: 110, name: "Black Death", alias: "Metal" },
  { id: 111, name: "Black Metal", alias: "Metal" },
  { id: 112, name: "Black Sludge", alias: "Metal" },
  { id: 113, name: "Black Thrash", alias: "Metal" },
  { id: 114, name: "Blackgaze", alias: "Metal" },
  { id: 115, name: "Blaskapelle", alias: "Jazz" },
  { id: 116, name: "Bluegrass", alias: "Country" },
  { id: 117, name: "Blues", alias: "Blues" },
  { id: 118, name: "Blues-rock", alias: "Blues" },
  { id: 119, name: "Blues-rock Guitar", alias: "Blues" },
  { id: 120, name: "Bmore", alias: "Electronic" },
  { id: 121, name: "Bolero", alias: "Other" },
  { id: 122, name: "Boogaloo", alias: "Other" },
  { id: 123, name: "Boogie-woogie", alias: "Jazz" },
  { id: 124, name: "Bossa Nova", alias: "Other" },
  { id: 125, name: "Bossa Nova Jazz", alias: "Jazz" },
  { id: 126, name: "Boston Rock", alias: "Rock" },
  { id: 127, name: "Bounce", alias: "Dance" },
  { id: 128, name: "Bouncy House", alias: "Dance" },
  { id: 129, name: "Bow Pop", alias: "Pop" },
  { id: 130, name: "Boy Band", alias: "Pop" },
  { id: 131, name: "Brass Band", alias: "Jazz" },
  { id: 132, name: "Brass Ensemble", alias: "Jazz" },
  { id: 133, name: "Brazilian Composition", alias: "Other" },
  { id: 134, name: "Brazilian Gospel", alias: "Other" },
  { id: 135, name: "Brazilian Hip Hop", alias: "Hip Hop" },
  { id: 136, name: "Brazilian Indie", alias: "Indie Rock" },
  { id: 137, name: "Brazilian Pop Music", alias: "Pop" },
  { id: 138, name: "Brazilian Punk", alias: "Punk" },
  { id: 139, name: "Breakbeat", alias: "Dance" },
  { id: 140, name: "Breakcore", alias: "Electronic" },
  { id: 141, name: "Breaks", alias: "Dance" },
  { id: 142, name: "Brega", alias: "Other" },
  { id: 143, name: "Breton Folk", alias: "Folk" },
  { id: 144, name: "Brill Building Pop", alias: "Pop" },
  { id: 145, name: "British Alternative Rock", alias: "Rock" },
  { id: 146, name: "British Blues", alias: "Blues" },
  { id: 147, name: "British Brass Band", alias: "Jazz" },
  { id: 148, name: "British Dance Band", alias: "Dance" },
  { id: 149, name: "British Folk", alias: "Folk" },
  { id: 150, name: "British Indie Rock", alias: "Indie Rock" },
  { id: 151, name: "British Invasion", alias: "Rock" },
  { id: 152, name: "Britpop", alias: "Pop" },
  { id: 153, name: "Broadway", alias: "Other" },
  { id: 154, name: "Broken Beat", alias: "Dance" },
  { id: 155, name: "Brooklyn Indie", alias: "Indie Rock" },
  { id: 156, name: "Brostep", alias: "Dance" },
  { id: 157, name: "Brutal Death Metal", alias: "Metal" },
  { id: 158, name: "Brutal Deathcore", alias: "Metal" },
  { id: 159, name: "Bubble Trance", alias: "Electronic" },
  { id: 160, name: "Bubblegum Dance", alias: "Dance" },
  { id: 161, name: "Bubblegum Pop", alias: "Pop" },
  { id: 162, name: "Bulgarian Rock", alias: "Rock" },
  { id: 163, name: "Byzantine", alias: "Classical" },
  { id: 164, name: "C-pop", alias: "Pop" },
  { id: 165, name: "C64", alias: "Electronic" },
  { id: 166, name: "C86", alias: "Indie Pop" },
  { id: 167, name: "Cabaret", alias: "Jazz" },
  { id: 168, name: "Cajun", alias: "Folk" },
  { id: 169, name: "Calypso", alias: "Reggae" },
  { id: 170, name: "Canadian Country", alias: "Country" },
  { id: 171, name: "Canadian Hip Hop", alias: "Hip Hop" },
  { id: 172, name: "Canadian Indie", alias: "Indie Rock" },
  { id: 173, name: "Canadian Metal", alias: "Metal" },
  { id: 174, name: "Canadian Pop", alias: "Pop" },
  { id: 175, name: "Candy Pop", alias: "Pop" },
  { id: 176, name: "Cantautor", alias: "Folk" },
  { id: 177, name: "Cante Flamenco", alias: "Other" },
  { id: 178, name: "Canterbury Scene", alias: "Rock" },
  { id: 179, name: "Cantopop", alias: "Pop" },
  { id: 180, name: "Canzone Napoletana", alias: "Other" },
  { id: 181, name: "Capoeira", alias: "Other" },
  { id: 182, name: "Carnatic", alias: "Classical" },
  { id: 183, name: "Catstep", alias: "Electronic" },
  { id: 184, name: "Caucasian Folk", alias: "Folk" },
  { id: 185, name: "Ccm", alias: "Other" },
  { id: 186, name: "Ceilidh", alias: "Folk" },
  { id: 187, name: "Cello", alias: "Classical" },
  { id: 188, name: "Celtic", alias: "Folk" },
  { id: 189, name: "Celtic Christmas", alias: "Other" },
  { id: 190, name: "Celtic Punk", alias: "Punk" },
  { id: 191, name: "Celtic Rock", alias: "Rock" },
  { id: 192, name: "Central Asian Folk", alias: "Folk" },
  { id: 193, name: "Chalga", alias: "Other" },
  { id: 194, name: "Chamber Pop", alias: "Pop" },
  { id: 195, name: "Chanson", alias: "Pop" },
  { id: 196, name: "Chanson Quebecois", alias: "Pop" },
  { id: 197, name: "Chaotic Black Metal", alias: "Metal" },
  { id: 198, name: "Chaotic Hardcore", alias: "Punk" },
  { id: 199, name: "Charred Death", alias: "Metal" },
  { id: 200, name: "Chicago Blues", alias: "Blues" },
  { id: 201, name: "Chicago House", alias: "Dance" },
  { id: 202, name: "Chicago Indie", alias: "Indie Rock" },
  { id: 203, name: "Chicago Soul", alias: "R&B" },
  { id: 204, name: "Chicano Rap", alias: "Rap" },
  { id: 205, name: "Children's Christmas", alias: "Other" },
  { id: 206, name: "Children's Music", alias: "Other" },
  { id: 207, name: "Chilean Rock", alias: "Rock" },
  { id: 208, name: "Chill Groove", alias: "Ambient" },
  { id: 209, name: "Chill Lounge", alias: "Ambient" },
  { id: 210, name: "Chill-out", alias: "Electronic" },
  { id: 211, name: "Chill-out Trance", alias: "Electronic" },
  { id: 212, name: "Chillstep", alias: "Electronic" },
  { id: 213, name: "Chillwave", alias: "Electronic" },
  { id: 214, name: "Chinese Indie Rock", alias: "Indie Rock" },
  { id: 215, name: "Chinese Opera", alias: "Other" },
  { id: 216, name: "Chinese Traditional", alias: "Other" },
  { id: 217, name: "Chip Hop", alias: "Hip Hop" },
  { id: 218, name: "Chiptune", alias: "Electronic" },
  { id: 219, name: "Choral", alias: "Classical" },
  { id: 220, name: "Choro", alias: "Other" },
  { id: 221, name: "Christian Alternative Rock", alias: "Alternative Rock" },
  { id: 222, name: "Christian Christmas", alias: "Other" },
  { id: 223, name: "Christian Dance", alias: "Dance" },
  { id: 224, name: "Christian Hardcore", alias: "Metal" },
  { id: 225, name: "Christian Hip Hop", alias: "Hip Hop" },
  { id: 226, name: "Christian Metal", alias: "Metal" },
  { id: 227, name: "Christian Music", alias: "Other" },
  { id: 228, name: "Christian Punk", alias: "Punk" },
  { id: 229, name: "Christian Rock", alias: "Rock" },
  { id: 230, name: "Christmas", alias: "Other" },
  { id: 231, name: "Christmas Product", alias: "Other" },
  { id: 232, name: "Cinematic Dubstep", alias: "Electronic" },
  { id: 233, name: "Clarinet", alias: "Jazz" },
  { id: 234, name: "Classic Afrobeat", alias: "Other" },
  { id: 235, name: "Classic Belgian Pop", alias: "Pop" },
  { id: 236, name: "Classic Chinese Pop", alias: "Pop" },
  { id: 237, name: "Classic Colombian Pop", alias: "Pop" },
  { id: 238, name: "Classic Czech Pop", alias: "Pop" },
  { id: 239, name: "Classic Danish Pop", alias: "Pop" },
  { id: 240, name: "Classic Dutch Pop", alias: "Pop" },
  { id: 241, name: "Classic Eurovision", alias: "Pop" },
  { id: 242, name: "Classic Finnish Pop", alias: "Pop" },
  { id: 243, name: "Classic Finnish Rock", alias: "Rock" },
  { id: 244, name: "Classic French Pop", alias: "Pop" },
  { id: 245, name: "Classic Funk Rock", alias: "Funk" },
  { id: 246, name: "Classic Garage Rock", alias: "Rock" },
  { id: 247, name: "Classic Italian Pop", alias: "Pop" },
  { id: 248, name: "Classic Norwegian Pop", alias: "Pop" },
  { id: 249, name: "Classic Peruvian Pop", alias: "Pop" },
  { id: 250, name: "Classic Polish Pop", alias: "Pop" },
  { id: 251, name: "Classic Psychedelic Rock", alias: "Psychedelic" },
  { id: 252, name: "Classic Rock", alias: "Rock" },
  { id: 253, name: "Classic Russian Pop", alias: "Pop" },
  { id: 254, name: "Classic Russian Rock", alias: "Rock" },
  { id: 255, name: "Classic Schlager", alias: "Pop" },
  { id: 256, name: "Classic Soundtrack", alias: "Other" },
  { id: 257, name: "Classic Swedish Pop", alias: "Pop" },
  { id: 258, name: "Classic Turkish Pop", alias: "Pop" },
  { id: 259, name: "Classic Venezuelan Pop", alias: "Pop" },
  { id: 260, name: "Classical", alias: "Classical" },
  { id: 261, name: "Classical Christmas", alias: "Other" },
  { id: 262, name: "Classical Flute", alias: "Classical" },
  { id: 263, name: "Classical Guitar", alias: "Classical" },
  { id: 264, name: "Classical Organ", alias: "Classical" },
  { id: 265, name: "Classical Performance", alias: "Classical" },
  { id: 266, name: "Classical Period", alias: "Classical" },
  { id: 267, name: "Classical Piano", alias: "Classical" },
  { id: 268, name: "College A Cappella", alias: "Other" },
  { id: 269, name: "College Marching Band", alias: "Other" },
  { id: 270, name: "Colombian Rock", alias: "Rock" },
  { id: 271, name: "Columbus Ohio Indie", alias: "Indie Rock" },
  { id: 272, name: "Comedy", alias: "Other" },
  { id: 273, name: "Comedy Rock", alias: "Rock" },
  { id: 274, name: "Comic", alias: "Other" },
  { id: 275, name: "Commons", alias: "Other" },
  { id: 276, name: "Complextro", alias: "Electronic" },
  { id: 277, name: "Composition D", alias: "Classical" },
  { id: 278, name: "Concert Piano", alias: "Classical" },
  { id: 279, name: "Consort", alias: "Other" },
  { id: 280, name: "Contemporary Classical", alias: "Classical" },
  { id: 281, name: "Contemporary Country", alias: "Country" },
  { id: 282, name: "Contemporary Folk", alias: "Folk" },
  { id: 283, name: "Contemporary Jazz", alias: "Jazz" },
  { id: 284, name: "Contemporary Post-bop", alias: "Jazz" },
  { id: 285, name: "Cool Jazz", alias: "Jazz" },
  { id: 286, name: "Corrosion", alias: "Metal" },
  { id: 287, name: "Corsican Folk", alias: "Folk" },
  { id: 288, name: "Country", alias: "Country" },
  { id: 289, name: "Country Blues", alias: "Blues" },
  { id: 290, name: "Country Christmas", alias: "Other" },
  { id: 291, name: "Country Dawn", alias: "Country" },
  { id: 292, name: "Country Gospel", alias: "Other" },
  { id: 293, name: "Country Road", alias: "Country" },
  { id: 294, name: "Country Rock", alias: "Country" },
  { id: 295, name: "Coupe Decale", alias: "Other" },
  { id: 296, name: "Coverchill", alias: "Chill" },
  { id: 297, name: "Covertrance", alias: "Electronic" },
  { id: 298, name: "Cowboy Western", alias: "Country" },
  { id: 299, name: "Cowpunk", alias: "Punk" },
  { id: 300, name: "Crack Rock Steady", alias: "Punk" },
  { id: 301, name: "Croatian Pop", alias: "Pop" },
  { id: 302, name: "Crossover Prog", alias: "Rock" },
  { id: 303, name: "Crossover Thrash", alias: "Metal" },
  { id: 304, name: "Crunk", alias: "Hip Hop" },
  { id: 305, name: "Crust Punk", alias: "Punk" },
  { id: 306, name: "Cryptic Black Metal", alias: "Metal" },
  { id: 307, name: "Cuban Rumba", alias: "Other" },
  { id: 308, name: "Cubaton", alias: "Other" },
  { id: 309, name: "Cumbia", alias: "Other" },
  { id: 310, name: "Cumbia Funk", alias: "Other" },
  { id: 311, name: "Cumbia Sonidera", alias: "Other" },
  { id: 312, name: "Cumbia Villera", alias: "Other" },
  { id: 313, name: "Cyber Metal", alias: "Metal" },
  { id: 314, name: "Czech Folk", alias: "Folk" },
  { id: 315, name: "Czech Rock", alias: "Rock" },
  { id: 316, name: "Dallas Indie", alias: "Indie Rock" },
  { id: 317, name: "Dance Pop", alias: "Dance" },
  { id: 318, name: "Dance Rock", alias: "Rock" },
  { id: 319, name: "Dance-punk", alias: "Punk" },
  { id: 320, name: "Dancehall", alias: "Reggae" },
  { id: 321, name: "Dangdut", alias: "Other" },
  { id: 322, name: "Danish Hip Hop", alias: "Hip Hop" },
  { id: 323, name: "Danish Indie", alias: "Indie Rock" },
  { id: 324, name: "Danish Jazz", alias: "Jazz" },
  { id: 325, name: "Danish Pop", alias: "Pop" },
  { id: 326, name: "Danish Pop Rock", alias: "Pop" },
  { id: 327, name: "Dansband", alias: "Country" },
  { id: 328, name: "Danseband", alias: "Country" },
  { id: 329, name: "Dansktop", alias: "Pop" },
  { id: 330, name: "Dark Ambient", alias: "Ambient" },
  { id: 331, name: "Dark Black Metal", alias: "Metal" },
  { id: 332, name: "Dark Cabaret", alias: "Jazz" },
  { id: 333, name: "Dark Electro-industrial", alias: "Electronic" },
  { id: 334, name: "Dark Hardcore", alias: "Electronic" },
  { id: 335, name: "Dark Jazz", alias: "Jazz" },
  { id: 336, name: "Dark Minimal Techno", alias: "Electronic" },
  { id: 337, name: "Dark Progressive House", alias: "Electronic" },
  { id: 338, name: "Dark Psytrance", alias: "Electronic" },
  { id: 339, name: "Dark Wave", alias: "Electronic" },
  { id: 340, name: "Darkstep", alias: "Electronic" },
  { id: 341, name: "Death Core", alias: "Metal" },
  { id: 342, name: "Death Metal", alias: "Metal" },
  { id: 343, name: "Deathgrind", alias: "Metal" },
  { id: 344, name: "Deep Acoustic Pop", alias: "Pop" },
  { id: 345, name: "Deep Adult Standards", alias: "Pop" },
  { id: 346, name: "Deep Alternative R&b", alias: "R&B" },
  { id: 347, name: "Deep Ambient", alias: "Ambient" },
  { id: 348, name: "Deep Baroque", alias: "Classical" },
  { id: 349, name: "Deep Brazilian Pop", alias: "Pop" },
  { id: 350, name: "Deep Breakcore", alias: "Electronic" },
  { id: 351, name: "Deep Canadian Indie", alias: "Indie Rock" },
  { id: 352, name: "Deep Ccm", alias: "Other" },
  { id: 353, name: "Deep Cello", alias: "Classical" },
  { id: 354, name: "Deep Chill", alias: "Chill" },
  { id: 355, name: "Deep Chill-out", alias: "Chill" },
  { id: 356, name: "Deep Christian Rock", alias: "Other" },
  { id: 357, name: "Deep Classic Garage Rock", alias: "Rock" },
  { id: 358, name: "Deep Classical Piano", alias: "Classical" },
  { id: 359, name: "Deep Comedy", alias: "Other" },
  { id: 360, name: "Deep Contemporary Country", alias: "Country" },
  { id: 361, name: "Deep Dance Pop", alias: "Dance" },
  { id: 362, name: "Deep Darkpsy", alias: "Electronic" },
  { id: 363, name: "Deep Deep House", alias: "Electronic" },
  { id: 364, name: "Deep Deep Tech House", alias: "Electronic" },
  { id: 365, name: "Deep Delta Blues", alias: "Blues" },
  { id: 366, name: "Deep Disco", alias: "Disco" },
  { id: 367, name: "Deep Disco House", alias: "Disco" },
  { id: 368, name: "Deep Discofox", alias: "Disco" },
  { id: 369, name: "Deep Downtempo Fusion", alias: "Electronic" },
  { id: 370, name: "Deep Dub Techno", alias: "Electronic" },
  { id: 371, name: "Deep East Coast Hip Hop", alias: "Hip Hop" },
  { id: 372, name: "Deep Euro House", alias: "Dance" },
  { id: 373, name: "Deep Eurodance", alias: "Electronic" },
  { id: 374, name: "Deep Filthstep", alias: "Electronic" },
  { id: 375, name: "Deep Flow", alias: "Hip Hop" },
  { id: 376, name: "Deep Folk Metal", alias: "Metal" },
  { id: 377, name: "Deep Free Jazz", alias: "Jazz" },
  { id: 378, name: "Deep Freestyle", alias: "Hip Hop" },
  { id: 379, name: "Deep Full On", alias: "Electronic" },
  { id: 380, name: "Deep Funk", alias: "Funk" },
  { id: 381, name: "Deep Funk House", alias: "Funk" },
  { id: 382, name: "Deep G Funk", alias: "Funk" },
  { id: 383, name: "Deep German Indie", alias: "Indie Rock" },
  { id: 384, name: "Deep German Punk", alias: "Punk" },
  { id: 385, name: "Deep Gothic Post-punk", alias: "Punk" },
  { id: 386, name: "Deep Happy Hardcore", alias: "Dance" },
  { id: 387, name: "Deep Hardcore", alias: "Punk" },
  { id: 388, name: "Deep Hardcore Punk", alias: "Punk" },
  { id: 389, name: "Deep Hardstyle", alias: "Electronic" },
  { id: 390, name: "Deep House", alias: "Dance" },
  { id: 391, name: "Deep Indian Pop", alias: "Pop" },
  { id: 392, name: "Deep Indie Pop", alias: "Pop" },
  { id: 393, name: "Deep Indie Rock", alias: "Rock" },
  { id: 394, name: "Deep Indie Singer-songwriter", alias: "Indie Rock" },
  { id: 395, name: "Deep Italo Disco", alias: "Dance" },
  { id: 396, name: "Deep Jazz Fusion", alias: "Jazz" },
  { id: 397, name: "Deep Jazz Guitar", alias: "Jazz" },
  { id: 398, name: "Deep Jazz Piano", alias: "Jazz" },
  { id: 399, name: "Deep Latin Alternative", alias: "Other" },
  { id: 400, name: "Deep Liquid", alias: "Dance" },
  { id: 401, name: "Deep Liquid Bass", alias: "Dance" },
  { id: 402, name: "Deep Melodic Death Metal", alias: "Metal" },
  { id: 403, name: "Deep Melodic Hard Rock", alias: "Rock" },
  { id: 404, name: "Deep Melodic House", alias: "Dance" },
  { id: 405, name: "Deep Melodic Metalcore", alias: "Metal" },
  { id: 406, name: "Deep Minimal Techno", alias: "Electronic" },
  { id: 407, name: "Deep Motown", alias: "Soul" },
  { id: 408, name: "Deep Neo-synthpop", alias: "Electronic" },
  { id: 409, name: "Deep Neofolk", alias: "Folk" },
  { id: 410, name: "Deep New Wave", alias: "Rock" },
  { id: 411, name: "Deep Nordic Folk", alias: "Folk" },
  { id: 412, name: "Deep Northern Soul", alias: "Soul" },
  { id: 413, name: "Deep Opera", alias: "Classical" },
  { id: 414, name: "Deep Orchestral", alias: "Classical" },
  { id: 415, name: "Deep Orgcore", alias: "Rock" },
  { id: 416, name: "Deep Pop Emo", alias: "Pop" },
  { id: 417, name: "Deep Pop Punk", alias: "Pop" },
  { id: 418, name: "Deep Power-pop Punk", alias: "Pop" },
  { id: 419, name: "Deep Progressive House", alias: "Dance" },
  { id: 420, name: "Deep Progressive Trance", alias: "Electronic" },
  { id: 421, name: "Deep Psychobilly", alias: "Punk" },
  { id: 422, name: "Deep Psytrance", alias: "Electronic" },
  { id: 423, name: "Deep Punk Rock", alias: "Punk" },
  { id: 424, name: "Deep Ragga", alias: "Reggae" },
  { id: 425, name: "Deep Rai", alias: "Other" },
  { id: 426, name: "Deep Regional Mexican", alias: "Other" },
  { id: 427, name: "Deep Smooth Jazz", alias: "Jazz" },
  { id: 428, name: "Deep Soft Rock", alias: "Rock" },
  { id: 429, name: "Deep Soul House", alias: "Soul" },
  { id: 430, name: "Deep Soundtrack", alias: "Ambient" },
  { id: 431, name: "Deep Southern Soul", alias: "Soul" },
  { id: 432, name: "Deep Space Rock", alias: "Rock" },
  { id: 433, name: "Deep String Quartet", alias: "Classical" },
  { id: 434, name: "Deep Sunset Lounge", alias: "Chill" },
  { id: 435, name: "Deep Surf Music", alias: "Rock" },
  { id: 436, name: "Deep Symphonic Black Metal", alias: "Metal" },
  { id: 437, name: "Deep Talent Show", alias: "Indie Rock" },
  { id: 438, name: "Deep Tech House", alias: "Electronic" },
  { id: 439, name: "Deep Thrash Metal", alias: "Metal" },
  { id: 440, name: "Deep Trap", alias: "Rap" },
  { id: 441, name: "Deep Turkish Pop", alias: "Pop" },
  { id: 442, name: "Deep Uplifting Trance", alias: "Electronic" },
  { id: 443, name: "Deep Vocal House", alias: "Dance" },
  { id: 444, name: "Deep Vocal Jazz", alias: "Jazz" },
  { id: 445, name: "Delta Blues", alias: "Blues" },
  { id: 446, name: "Demoscene", alias: "Game" },
  { id: 447, name: "Denver Indie", alias: "Indie Rock" },
  { id: 448, name: "Depressive Black Metal", alias: "Metal" },
  { id: 449, name: "Desert Blues", alias: "Blues" },
  { id: 450, name: "Desi", alias: "Other" },
  { id: 451, name: "Destroy Techno", alias: "Electronic" },
  { id: 452, name: "Detroit Hip Hop", alias: "Hip Hop" },
  { id: 453, name: "Detroit Techno", alias: "Electronic" },
  { id: 454, name: "Didgeridoo", alias: "Other" },
  { id: 455, name: "Digital Hardcore", alias: "Punk" },
  { id: 456, name: "Dirty South Rap", alias: "Rap" },
  { id: 457, name: "Dirty Texas Rap", alias: "Rap" },
  { id: 458, name: "Disco", alias: "Disco" },
  { id: 459, name: "Disco House", alias: "Dance" },
  { id: 460, name: "Discofox", alias: "Disco" },
  { id: 461, name: "Dixieland", alias: "Jazz" },
  { id: 462, name: "Djent", alias: "Metal" },
  { id: 463, name: "Dominican Pop", alias: "Pop" },
  { id: 464, name: "Doo-wop", alias: "R&B" },
  { id: 465, name: "Doom Metal", alias: "Metal" },
  { id: 466, name: "Doomcore", alias: "Metal" },
  { id: 467, name: "Doujin", alias: "Electronic" },
  { id: 468, name: "Downtempo", alias: "Chill" },
  { id: 469, name: "Downtempo Fusion", alias: "Chill" },
  { id: 470, name: "Downtempo Trip Hop", alias: "Chill" },
  { id: 471, name: "Drama", alias: "Other" },
  { id: 472, name: "Dream Pop", alias: "Indie Rock" },
  { id: 473, name: "Dreamo", alias: "Ambient" },
  { id: 474, name: "Drill And Bass", alias: "Dance" },
  { id: 475, name: "Drone", alias: "Grunge" },
  { id: 476, name: "Drone Folk", alias: "Folk" },
  { id: 477, name: "Drone Metal", alias: "Metal" },
  { id: 478, name: "Drone Psych", alias: "Psychedelic" },
  { id: 479, name: "Drum And Bass", alias: "Dance" },
  { id: 480, name: "Drumfunk", alias: "Dance" },
  { id: 481, name: "Dub", alias: "Reggae" },
  { id: 482, name: "Dub Techno", alias: "Electronic" },
  { id: 483, name: "Dubstep", alias: "Dance" },
  { id: 484, name: "Dubstep Product", alias: "Dance" },
  { id: 485, name: "Dubsteppe", alias: "Electronic" },
  { id: 486, name: "Duranguense", alias: "Country" },
  { id: 487, name: "Dutch Hip Hop", alias: "Hip Hop" },
  { id: 488, name: "Dutch House", alias: "Dance" },
  { id: 489, name: "Dutch Pop", alias: "Pop" },
  { id: 490, name: "Dutch Rock", alias: "Rock" },
  { id: 491, name: "E6fi", alias: "Indie Rock" },
  { id: 492, name: "Early Music", alias: "Pop" },
  { id: 493, name: "Early Music Ensemble", alias: "Classical" },
  { id: 494, name: "East Coast Hip Hop", alias: "Hip Hop" },
  { id: 495, name: "Easy Listening", alias: "Ambient" },
  { id: 496, name: "Ebm", alias: "Electronic" },
  { id: 497, name: "Ectofolk", alias: "Folk" },
  { id: 498, name: "Ecuadoria", alias: "Other" },
  { id: 499, name: "Edm", alias: "Dance" },
  { id: 500, name: "Electric Blues", alias: "Blues" },
  { id: 501, name: "Electro", alias: "Electronic" },
  { id: 502, name: "Electro Dub", alias: "Electronic" },
  { id: 503, name: "Electro House", alias: "Dance" },
  { id: 504, name: "Electro Jazz", alias: "Jazz" },
  { id: 505, name: "Electro Latino", alias: "Other" },
  { id: 506, name: "Electro Swing", alias: "Jazz" },
  { id: 507, name: "Electro Trash", alias: "Electronic" },
  { id: 508, name: "Electro-industrial", alias: "Electronic" },
  { id: 509, name: "Electroacoustic Improvisation", alias: "Ambient" },
  { id: 510, name: "Electroclash", alias: "Electronic" },
  { id: 511, name: "Electrofox", alias: "Electronic" },
  { id: 512, name: "Electronic", alias: "Electronic" },
  { id: 513, name: "Electronica", alias: "Electronic" },
  { id: 514, name: "Electronicore", alias: "Metal" },
  { id: 515, name: "Electropowerpop", alias: "Pop" },
  { id: 516, name: "Electropunk", alias: "Punk" },
  { id: 517, name: "Emo", alias: "Emo" },
  { id: 518, name: "Emo Punk", alias: "Emo" },
  { id: 519, name: "Enka", alias: "Pop" },
  { id: 520, name: "Entehno", alias: "Electronic" },
  { id: 521, name: "Environmental", alias: "Ambient" },
  { id: 522, name: "Epicore", alias: "Metal" },
  { id: 523, name: "Estonian Pop", alias: "Pop" },
  { id: 524, name: "Ethereal Gothic", alias: "Punk" },
  { id: 525, name: "Ethereal Wave", alias: "Ambient" },
  { id: 526, name: "Etherpop", alias: "Pop" },
  { id: 527, name: "Ethiopian Pop", alias: "Pop" },
  { id: 528, name: "Eurobeat", alias: "Electronic" },
  { id: 529, name: "Eurodance", alias: "Dance" },
  { id: 530, name: "Europop", alias: "Pop" },
  { id: 531, name: "Euroska", alias: "Punk" },
  { id: 532, name: "Eurovision", alias: "Pop" },
  { id: 533, name: "Exotica", alias: "Ambient" },
  { id: 534, name: "Experimental", alias: "Other" },
  { id: 535, name: "Experimental Dubstep", alias: "Electronic" },
  { id: 536, name: "Experimental Psych", alias: "Psychedelic" },
  { id: 537, name: "Experimental Rock", alias: "Rock" },
  { id: 538, name: "Fado", alias: "Other" },
  { id: 539, name: "Fake", alias: "Electronic" },
  { id: 540, name: "Fallen Angel", alias: "Metal" },
  { id: 541, name: "Faroese Pop", alias: "Pop" },
  { id: 542, name: "Fast Melodic Punk", alias: "Punk" },
  { id: 543, name: "Fidget House", alias: "Electronic" },
  { id: 544, name: "Filmi", alias: "Other" },
  { id: 545, name: "Filter House", alias: "Electronic" },
  { id: 546, name: "Filthstep", alias: "Electronic" },
  { id: 547, name: "Fingerstyle", alias: "Blues" },
  { id: 548, name: "Finnish Hardcore", alias: "Metal" },
  { id: 549, name: "Finnish Hip Hop", alias: "Hip Hop" },
  { id: 550, name: "Finnish Indie", alias: "Indie Rock" },
  { id: 551, name: "Finnish Jazz", alias: "Jazz" },
  { id: 552, name: "Finnish Metal", alias: "Metal" },
  { id: 553, name: "Finnish Pop", alias: "Pop" },
  { id: 554, name: "Flamenco", alias: "Other" },
  { id: 555, name: "Flick Hop", alias: "Hip Hop" },
  { id: 556, name: "Folk", alias: "Folk" },
  { id: 557, name: "Folk Christmas", alias: "Folk" },
  { id: 558, name: "Folk Metal", alias: "Metal" },
  { id: 559, name: "Folk Punk", alias: "Punk" },
  { id: 560, name: "Folk Rock", alias: "Rock" },
  { id: 561, name: "Folk-pop", alias: "Pop" },
  { id: 562, name: "Folk-prog", alias: "Folk" },
  { id: 563, name: "Folklore Argentino", alias: "Folk" },
  { id: 564, name: "Folkmusik", alias: "Folk" },
  { id: 565, name: "Footwork", alias: "Electronic" },
  { id: 566, name: "Forro", alias: "Other" },
  { id: 567, name: "Fourth World", alias: "Other" },
  { id: 568, name: "Freak Folk", alias: "Folk" },
  { id: 569, name: "Freakbeat", alias: "Rock" },
  { id: 570, name: "Free Improvisation", alias: "Jazz" },
  { id: 571, name: "Free Jazz", alias: "Jazz" },
  { id: 572, name: "Freestyle", alias: "Electronic" },
  { id: 573, name: "French Folk", alias: "Folk" },
  { id: 574, name: "French Folk Pop", alias: "Pop" },
  { id: 575, name: "French Hip Hop", alias: "Hip Hop" },
  { id: 576, name: "French Indie Pop", alias: "Pop" },
  { id: 577, name: "French Movie Tunes", alias: "Other" },
  { id: 578, name: "French Pop", alias: "Pop" },
  { id: 579, name: "French Punk", alias: "Punk" },
  { id: 580, name: "French Reggae", alias: "Reggae" },
  { id: 581, name: "French Rock", alias: "Rock" },
  { id: 582, name: "Full On", alias: "Electronic" },
  { id: 583, name: "Funeral Doom", alias: "Metal" },
  { id: 584, name: "Funk", alias: "Funk" },
  { id: 585, name: "Funk Carioca", alias: "Hip Hop" },
  { id: 586, name: "Funk Metal", alias: "Metal" },
  { id: 587, name: "Funk Rock", alias: "Rock" },
  { id: 588, name: "Funky Breaks", alias: "Dance" },
  { id: 589, name: "Future Ambient", alias: "Ambient" },
  { id: 590, name: "Future Garage", alias: "Dance" },
  { id: 591, name: "Futurepop", alias: "Electronic" },
  { id: 592, name: "G Funk", alias: "Funk" },
  { id: 593, name: "Gabba", alias: "Electronic" },
  { id: 594, name: "Galego", alias: "Other" },
  { id: 595, name: "Gamecore", alias: "Game" },
  { id: 596, name: "Gamelan", alias: "Other" },
  { id: 597, name: "Gangster Rap", alias: "Hip Hop" },
  { id: 598, name: "Garage Pop", alias: "Pop" },
  { id: 599, name: "Garage Punk", alias: "Punk" },
  { id: 600, name: "Garage Punk Blues", alias: "Punk" },
  { id: 601, name: "Garage Rock", alias: "Rock" },
  { id: 602, name: "Gauze Pop", alias: "Pop" },
  { id: 603, name: "Gbvfi", alias: "Indie Rock" },
  { id: 604, name: "Geek Folk", alias: "Folk" },
  { id: 605, name: "Geek Rock", alias: "Rock" },
  { id: 606, name: "German Ccm", alias: "Pop" },
  { id: 607, name: "German Hip Hop", alias: "Hip Hop" },
  { id: 608, name: "German Indie", alias: "Indie Rock" },
  { id: 609, name: "German Metal", alias: "Metal" },
  { id: 610, name: "German Oi", alias: "Punk" },
  { id: 611, name: "German Pop", alias: "Pop" },
  { id: 612, name: "German Pop Rock", alias: "Pop" },
  { id: 613, name: "German Punk", alias: "Punk" },
  { id: 614, name: "German Show Tunes", alias: "Pop" },
  { id: 615, name: "German Techno", alias: "Electronic" },
  { id: 616, name: "Ghettotech", alias: "Dance" },
  { id: 617, name: "Ghoststep", alias: "Electronic" },
  { id: 618, name: "Girl Group", alias: "Pop" },
  { id: 619, name: "Glam Metal", alias: "Metal" },
  { id: 620, name: "Glam Rock", alias: "Rock" },
  { id: 621, name: "Glitch", alias: "Electronic" },
  { id: 622, name: "Glitch Beats", alias: "Electronic" },
  { id: 623, name: "Glitch Hop", alias: "Electronic" },
  { id: 624, name: "Glitter Trance", alias: "Electronic" },
  { id: 625, name: "Goa Trance", alias: "Electronic" },
  { id: 626, name: "Goregrind", alias: "Metal" },
  { id: 627, name: "Gospel", alias: "Blues" },
  { id: 628, name: "Gospel Blues", alias: "Blues" },
  { id: 629, name: "Gospel Reggae", alias: "Reggae" },
  { id: 630, name: "Gothic Alternative", alias: "Alternative Rock" },
  { id: 631, name: "Gothic Americana", alias: "Country" },
  { id: 632, name: "Gothic Doom", alias: "Metal" },
  { id: 633, name: "Gothic Metal", alias: "Metal" },
  { id: 634, name: "Gothic Post-punk", alias: "Punk" },
  { id: 635, name: "Gothic Rock", alias: "Rock" },
  { id: 636, name: "Gothic Symphonic Metal", alias: "Metal" },
  { id: 637, name: "Grave Wave", alias: "Electronic" },
  { id: 638, name: "Greek Hip Hop", alias: "Hip Hop" },
  { id: 639, name: "Greek House", alias: "Dance" },
  { id: 640, name: "Greek Indie", alias: "Indie Rock" },
  { id: 641, name: "Grim Death Metal", alias: "Metal" },
  { id: 642, name: "Grime", alias: "Dance" },
  { id: 643, name: "Grindcore", alias: "Metal" },
  { id: 644, name: "Grisly Death Metal", alias: "Metal" },
  { id: 645, name: "Groove Metal", alias: "Metal" },
  { id: 646, name: "Grunge", alias: "Rock" },
  { id: 647, name: "Grunge Pop", alias: "Rock" },
  { id: 648, name: "Grupera", alias: "Folk" },
  { id: 649, name: "Guidance", alias: "Other" },
  { id: 650, name: "Gypsy Jazz", alias: "Jazz" },
  { id: 651, name: "Hands Up", alias: "Electronic" },
  { id: 652, name: "Happy Hardcore", alias: "Electronic" },
  { id: 653, name: "Hard Alternative", alias: "Alternative Rock" },
  { id: 654, name: "Hard Bop", alias: "Jazz" },
  { id: 655, name: "Hard Glam", alias: "Rock" },
  { id: 656, name: "Hard House", alias: "Dance" },
  { id: 657, name: "Hard Rock", alias: "Rock" },
  { id: 658, name: "Hard Stoner Rock", alias: "Rock" },
  { id: 659, name: "Hard Trance", alias: "Electronic" },
  { id: 660, name: "Hardcore", alias: "Punk" },
  { id: 661, name: "Hardcore Breaks", alias: "Hip Hop" },
  { id: 662, name: "Hardcore Hip Hop", alias: "Hip Hop" },
  { id: 663, name: "Hardcore Punk", alias: "Punk" },
  { id: 664, name: "Hardcore Techno", alias: "Electronic" },
  { id: 665, name: "Hardstyle", alias: "Dance" },
  { id: 666, name: "Harmonica Blues", alias: "Blues" },
  { id: 667, name: "Harp", alias: "Classical" },
  { id: 668, name: "Hatecore", alias: "Metal" },
  { id: 669, name: "Hauntology", alias: "Electronic" },
  { id: 670, name: "Hawaiian", alias: "Other" },
  { id: 671, name: "Healing", alias: "Ambient" },
  { id: 672, name: "Heavy Alternative", alias: "Alternative Rock" },
  { id: 673, name: "Heavy Christmas", alias: "Other" },
  { id: 674, name: "Heavy Gothic Rock", alias: "Punk" },
  { id: 675, name: "Hi Nrg", alias: "Electronic" },
  { id: 676, name: "Highlife", alias: "Other" },
  { id: 677, name: "Hindustani Classical", alias: "Classical" },
  { id: 678, name: "Hip Hop", alias: "Hip Hop" },
  { id: 679, name: "Hip Hop Quebecois", alias: "Hip Hop" },
  { id: 680, name: "Hip Hop Tuga", alias: "Hip Hop" },
  { id: 681, name: "Hip House", alias: "Dance" },
  { id: 682, name: "Hip Pop", alias: "Pop" },
  { id: 683, name: "Hiplife", alias: "Other" },
  { id: 684, name: "Hoerspiel", alias: "Other" },
  { id: 685, name: "Hollywood", alias: "Other" },
  { id: 686, name: "Honky Tonk", alias: "Country" },
  { id: 687, name: "Horror Punk", alias: "Punk" },
  { id: 688, name: "Horrorcore", alias: "Hip Hop" },
  { id: 689, name: "House", alias: "Dance" },
  { id: 690, name: "Hungarian Hip Hop", alias: "Hip Hop" },
  { id: 691, name: "Hungarian Pop", alias: "Pop" },
  { id: 692, name: "Hungarian Rock", alias: "Rock" },
  { id: 693, name: "Hurban", alias: "Hip Hop" },
  { id: 694, name: "Hyphy", alias: "Hip Hop" },
  { id: 695, name: "Icelandic Pop", alias: "Pop" },
  { id: 696, name: "Idol", alias: "Pop" },
  { id: 697, name: "Illbient", alias: "Ambient" },
  { id: 698, name: "Indian Classical", alias: "Classical" },
  { id: 699, name: "Indian Pop", alias: "Pop" },
  { id: 700, name: "Indian Rock", alias: "Rock" },
  { id: 701, name: "Indie Christmas", alias: "Other" },
  { id: 702, name: "Indie Dream Pop", alias: "Indie Rock" },
  { id: 703, name: "Indie Emo", alias: "Indie Rock" },
  { id: 704, name: "Indie Emo Rock", alias: "Indie Rock" },
  { id: 705, name: "Indie Folk", alias: "Folk" },
  { id: 706, name: "Indie Fuzzpop", alias: "Indie Rock" },
  { id: 707, name: "Indie Pop", alias: "Pop" },
  { id: 708, name: "Indie Pop Rock", alias: "Pop" },
  { id: 709, name: "Indie Post-punk", alias: "Punk" },
  { id: 710, name: "Indie Psych-pop", alias: "Pop" },
  { id: 711, name: "Indie R&b", alias: "R&B" },
  { id: 712, name: "Indie Rock", alias: "Rock" },
  { id: 713, name: "Indie Shoegaze", alias: "Indie Rock" },
  { id: 714, name: "Indie Singer-songwriter", alias: "Indie Rock" },
  { id: 715, name: "Indietronica", alias: "Indie Rock" },
  { id: 716, name: "Indonesian Indie", alias: "Indie Rock" },
  { id: 717, name: "Indonesian Pop", alias: "Pop" },
  { id: 718, name: "Indorock", alias: "Rock" },
  { id: 719, name: "Industrial", alias: "Electronic" },
  { id: 720, name: "Industrial Metal", alias: "Metal" },
  { id: 721, name: "Industrial Rock", alias: "Rock" },
  { id: 722, name: "Instrumental Post Rock", alias: "Indie Rock" },
  { id: 723, name: "Intelligent Dance Music", alias: "Electronic" },
  { id: 724, name: "Irish Folk", alias: "Folk" },
  { id: 725, name: "Irish Indie", alias: "Indie Rock" },
  { id: 726, name: "Irish Rock", alias: "Rock" },
  { id: 727, name: "Iskelma", alias: "Folk" },
  { id: 728, name: "Islamic Recitation", alias: "Other" },
  { id: 729, name: "Israeli Rock", alias: "Rock" },
  { id: 730, name: "Italian Disco", alias: "Disco" },
  { id: 731, name: "Italian Folk", alias: "Folk" },
  { id: 732, name: "Italian Hip Hop", alias: "Hip Hop" },
  { id: 733, name: "Italian Indie Pop", alias: "Pop" },
  { id: 734, name: "Italian Jazz", alias: "Jazz" },
  { id: 735, name: "Italian Pop", alias: "Pop" },
  { id: 736, name: "Italian Pop Rock", alias: "Pop Rock" },
  { id: 737, name: "Italian Progressive Rock", alias: "Rock" },
  { id: 738, name: "Italian Punk", alias: "Punk" },
  { id: 739, name: "Italo Dance", alias: "Dance" },
  { id: 740, name: "J-alt", alias: "Alternative Rock" },
  { id: 741, name: "J-ambient", alias: "Ambient" },
  { id: 742, name: "J-core", alias: "Dance" },
  { id: 743, name: "J-dance", alias: "Dance" },
  { id: 744, name: "J-idol", alias: "Pop" },
  { id: 745, name: "J-indie", alias: "Indie Rock" },
  { id: 746, name: "J-metal", alias: "Metal" },
  { id: 747, name: "J-pop", alias: "Pop" },
  { id: 748, name: "J-poppunk", alias: "Punk" },
  { id: 749, name: "J-poprock", alias: "Pop Rock" },
  { id: 750, name: "J-punk", alias: "Punk" },
  { id: 751, name: "J-rap", alias: "Rap" },
  { id: 752, name: "J-rock", alias: "Rock" },
  { id: 753, name: "J-theme", alias: "Ambient" },
  { id: 754, name: "Jam Band", alias: "Rock" },
  { id: 755, name: "Jangle Pop", alias: "Indie Rock" },
  { id: 756, name: "Jangle Rock", alias: "Indie Rock" },
  { id: 757, name: "Japanese Jazztronica", alias: "Jazz" },
  { id: 758, name: "Japanese Psychedelic", alias: "Psychedelic" },
  { id: 759, name: "Japanese R&b", alias: "R&B" },
  { id: 760, name: "Japanese Standards", alias: "Jazz" },
  { id: 761, name: "Japanese Traditional", alias: "Other" },
  { id: 762, name: "Japanoise", alias: "Indie Rock" },
  { id: 763, name: "Jazz", alias: "Jazz" },
  { id: 764, name: "Jazz Bass", alias: "Jazz" },
  { id: 765, name: "Jazz Blues", alias: "Jazz" },
  { id: 766, name: "Jazz Brass", alias: "Jazz" },
  { id: 767, name: "Jazz Christmas", alias: "Jazz" },
  { id: 768, name: "Jazz Funk", alias: "Jazz" },
  { id: 769, name: "Jazz Fusion", alias: "Jazz" },
  { id: 770, name: "Jazz Metal", alias: "Metal" },
  { id: 771, name: "Jazz Orchestra", alias: "Jazz" },
  { id: 772, name: "Jazz Trio", alias: "Jazz" },
  { id: 773, name: "Jerk", alias: "Electronic" },
  { id: 774, name: "Jig And Reel", alias: "Folk" },
  { id: 775, name: "Judaica", alias: "Other" },
  { id: 776, name: "Jug Band", alias: "Blues" },
  { id: 777, name: "Juggalo", alias: "Hip Hop" },
  { id: 778, name: "Jump Blues", alias: "Blues" },
  { id: 779, name: "Jump Up", alias: "Reggae" },
  { id: 780, name: "Jumpstyle", alias: "Electronic" },
  { id: 781, name: "Jungle", alias: "Tropical" },
  { id: 782, name: "K-hop", alias: "Pop" },
  { id: 783, name: "K-indie", alias: "Indie Rock" },
  { id: 784, name: "K-pop", alias: "Pop" },
  { id: 785, name: "K-rock", alias: "Rock" },
  { id: 786, name: "Kabarett", alias: "Other" },
  { id: 787, name: "Karneval", alias: "Other" },
  { id: 788, name: "Kc Indie", alias: "Indie Rock" },
  { id: 789, name: "Kindermusik", alias: "Other" },
  { id: 790, name: "Kirtan", alias: "Ambient" },
  { id: 791, name: "Kiwi Rock", alias: "Rock" },
  { id: 792, name: "Kizomba", alias: "Dance" },
  { id: 793, name: "Klapa", alias: "Ambient" },
  { id: 794, name: "Klezmer", alias: "Jazz" },
  { id: 795, name: "Kompa", alias: "Reggae" },
  { id: 796, name: "Kraut Rock", alias: "Rock" },
  { id: 797, name: "Kuduro", alias: "Dancec" },
  { id: 798, name: "Kurdish Folk", alias: "Folk" },
  { id: 799, name: "Kwaito", alias: "Dance" },
  { id: 800, name: "La Indie", alias: "Indie Rock" },
  { id: 801, name: "Laboratorio", alias: "Jazz" },
  { id: 802, name: "Laiko", alias: "Other" },
  { id: 803, name: "Latin", alias: "Other" },
  { id: 804, name: "Latin Alternative", alias: "Other" },
  { id: 805, name: "Latin Christian", alias: "Other" },
  { id: 806, name: "Latin Christmas", alias: "Other" },
  { id: 807, name: "Latin Electronica", alias: "Electronic" },
  { id: 808, name: "Latin Hip Hop", alias: "Hip Hop" },
  { id: 809, name: "Latin Jazz", alias: "Jazz" },
  { id: 810, name: "Latin Metal", alias: "Metal" },
  { id: 811, name: "Latin Pop", alias: "Pop" },
  { id: 812, name: "Latvian Pop", alias: "Pop" },
  { id: 813, name: "Lds", alias: "Christian" },
  { id: 814, name: "Leeds Indie", alias: "Indie Rock" },
  { id: 815, name: "Levenslied", alias: "Folk" },
  { id: 816, name: "Liedermacher", alias: "Folk" },
  { id: 817, name: "Light Music", alias: "Pop" },
  { id: 818, name: "Lilith", alias: "Pop" },
  { id: 819, name: "Liquid Funk", alias: "Funk" },
  { id: 820, name: "Lithumania", alias: "Other" },
  { id: 821, name: "Liturgical", alias: "Classical" },
  { id: 822, name: "Lo Star", alias: "Pop" },
  { id: 823, name: "Lo-fi", alias: "Chill" },
  { id: 824, name: "Louisiana Blues", alias: "Blues" },
  { id: 825, name: "Louisville Indie", alias: "Indie Rock" },
  { id: 826, name: "Lounge", alias: "Ambient" },
  { id: 827, name: "Lounge House", alias: "Ambient" },
  { id: 828, name: "Lovers Rock", alias: "Reggae" },
  { id: 829, name: "Lowercase", alias: "Ambient" },
  { id: 830, name: "Luk Thung", alias: "Other" },
  { id: 831, name: "Madchester", alias: "Alternative Rock" },
  { id: 832, name: "Maghreb", alias: "Other" },
  { id: 833, name: "Magyar", alias: "Folk" },
  { id: 834, name: "Makossa", alias: "Dance" },
  { id: 835, name: "Malagasy Folk", alias: "Folk" },
  { id: 836, name: "Malaysian Pop", alias: "Pop" },
  { id: 837, name: "Mallet", alias: "Other" },
  { id: 838, name: "Mambo", alias: "Latin" },
  { id: 839, name: "Mande Pop", alias: "Other" },
  { id: 840, name: "Mandopop", alias: "Pop" },
  { id: 841, name: "Manele", alias: "Other" },
  { id: 842, name: "Marching Band", alias: "Other" },
  { id: 843, name: "Mariachi", alias: "Other" },
  { id: 844, name: "Martial Industrial", alias: "Electronic" },
  { id: 845, name: "Mashup", alias: "Electronic" },
  { id: 846, name: "Math Pop", alias: "Pop" },
  { id: 847, name: "Math Rock", alias: "Rock" },
  { id: 848, name: "Mathcore", alias: "Metal" },
  { id: 849, name: "Mbalax", alias: "Other" },
  { id: 850, name: "Medieval", alias: "Classical" },
  { id: 851, name: "Medieval Rock", alias: "Rock" },
  { id: 852, name: "Meditation", alias: "Ambient" },
  { id: 853, name: "Melancholia", alias: "Ambient" },
  { id: 854, name: "Melbourne Bounce", alias: "Dance" },
  { id: 855, name: "Mellow Gold", alias: "Pop" },
  { id: 856, name: "Melodic Death Metal", alias: "Metal" },
  { id: 857, name: "Melodic Hard Rock", alias: "Rock" },
  { id: 858, name: "Melodic Hardcore", alias: "Rock" },
  { id: 859, name: "Melodic Metalcore", alias: "Metal" },
  { id: 860, name: "Melodic Power Metal", alias: "Metal" },
  { id: 861, name: "Melodic Progressive Metal", alias: "Metal" },
  { id: 862, name: "Memphis Blues", alias: "Blues" },
  { id: 863, name: "Memphis Hip Hop", alias: "Hip Hop" },
  { id: 864, name: "Memphis Soul", alias: "Soul" },
  { id: 865, name: "Merengue", alias: "Other" },
  { id: 866, name: "Merengue Urbano", alias: "Other" },
  { id: 867, name: "Merseybeat", alias: "Rock" },
  { id: 868, name: "Metal", alias: "Metal" },
  { id: 869, name: "Metal Guitar", alias: "Metal" },
  { id: 870, name: "Metalcore", alias: "Metal" },
  { id: 871, name: "Metropopolis", alias: "Electronic" },
  { id: 872, name: "Mexican Indie", alias: "Indie Rock" },
  { id: 873, name: "Mexican Rock-and-roll", alias: "Rock" },
  { id: 874, name: "Mexican Son", alias: "Folk" },
  { id: 875, name: "Mexican Traditional", alias: "Folk" },
  { id: 876, name: "Miami Bass", alias: "Dance" },
  { id: 877, name: "Michigan Indie", alias: "Indie Rock" },
  { id: 878, name: "Microhouse", alias: "Electronic" },
  { id: 879, name: "Military Band", alias: "Other" },
  { id: 880, name: "Minimal", alias: "Ambient" },
  { id: 881, name: "Minimal Dub", alias: "Dance" },
  { id: 882, name: "Minimal Dubstep", alias: "Dance" },
  { id: 883, name: "Minimal Melodic Techno", alias: "Electronic" },
  { id: 884, name: "Minimal Tech House", alias: "Electronic" },
  { id: 885, name: "Minimal Techno", alias: "Electronic" },
  { id: 886, name: "Minimal Wave", alias: "Electronic" },
  { id: 887, name: "Mizrahi", alias: "Other" },
  { id: 888, name: "Mod Revival", alias: "Rock" },
  { id: 889, name: "Modern Blues", alias: "Blues" },
  { id: 890, name: "Modern Classical", alias: "Classical" },
  { id: 891, name: "Modern Country Rock", alias: "Country" },
  { id: 892, name: "Modern Downshift", alias: "Electronic" },
  { id: 893, name: "Modern Free Jazz", alias: "Jazz" },
  { id: 894, name: "Modern Performance", alias: "Dance" },
  { id: 895, name: "Modern Southern Rock", alias: "Rock" },
  { id: 896, name: "Modern Uplift", alias: "Electronic" },
  { id: 897, name: "Monastic", alias: "Ambient" },
  { id: 898, name: "Moombahton", alias: "Dance" },
  { id: 899, name: "Morna", alias: "Other" },
  { id: 900, name: "Motivation", alias: "Ambient" },
  { id: 901, name: "Motown", alias: "R&B" },
  { id: 902, name: "Movie Tunes", alias: "Pop" },
  { id: 903, name: "Mpb", alias: "Pop" },
  { id: 904, name: "Musica Para Ninos", alias: "Other" },
  { id: 905, name: "Musiikkia Lapsille", alias: "Other" },
  { id: 906, name: "Musique Concrete", alias: "Other" },
  { id: 907, name: "Musique Pour Enfants", alias: "Other" },
  { id: 908, name: "Muziek Voor Kinderen", alias: "Other" },
  { id: 909, name: "Nasheed", alias: "Ambient" },
  { id: 910, name: "Nashville Sound", alias: "Country" },
  { id: 911, name: "Native American", alias: "Other" },
  { id: 912, name: "Necrogrind", alias: "Metal" },
  { id: 913, name: "Neo Classical Metal", alias: "Metal" },
  { id: 914, name: "Neo Honky Tonk", alias: "Country" },
  { id: 915, name: "Neo Mellow", alias: "Pop" },
  { id: 916, name: "Neo Metal", alias: "Metal" },
  { id: 917, name: "Neo Soul", alias: "Soul" },
  { id: 918, name: "Neo Soul-jazz", alias: "Soul" },
  { id: 919, name: "Neo-industrial Rock", alias: "Rock" },
  { id: 920, name: "Neo-pagan", alias: "Other" },
  { id: 921, name: "Neo-progressive", alias: "Rock" },
  { id: 922, name: "Neo-psychedelic", alias: "Psychedelic" },
  { id: 923, name: "Neo-rockabilly", alias: "Rock" },
  { id: 924, name: "Neo-singer-songwriter", alias: "Folk" },
  { id: 925, name: "Neo-synthpop", alias: "Indie Pop" },
  { id: 926, name: "Neo-trad Metal", alias: "Metal" },
  { id: 927, name: "Neo-traditional Country", alias: "Country" },
  { id: 928, name: "Neoclassical", alias: "Classical" },
  { id: 929, name: "Neofolk", alias: "Folk" },
  { id: 930, name: "Nepali", alias: "Other" },
  { id: 931, name: "Nerdcore", alias: "Hip Hop" },
  { id: 932, name: "Neue Deutsche Harte", alias: "Metal" },
  { id: 933, name: "Neue Deutsche Welle", alias: "Electronic" },
  { id: 934, name: "Neurofunk", alias: "Funk" },
  { id: 935, name: "Neurostep", alias: "Electronic" },
  { id: 936, name: "New Age", alias: "Ambient" },
  { id: 937, name: "New Age Piano", alias: "Ambient" },
  { id: 938, name: "New Americana", alias: "Country" },
  { id: 939, name: "New Beat", alias: "Dance" },
  { id: 940, name: "New Jack Smooth", alias: "R&B" },
  { id: 941, name: "New Jack Swing", alias: "R&B" },
  { id: 942, name: "New Orleans Blues", alias: "Blues" },
  { id: 943, name: "New Orleans Jazz", alias: "Jazz" },
  { id: 944, name: "New Rave", alias: "Dance" },
  { id: 945, name: "New Romantic", alias: "Pop" },
  { id: 946, name: "New Tribe", alias: "Dance" },
  { id: 947, name: "New Wave", alias: "Punk" },
  { id: 948, name: "New Wave Pop", alias: "Pop" },
  { id: 949, name: "New Weird America", alias: "Folk" },
  { id: 950, name: "Ninja", alias: "Dance" },
  { id: 951, name: "Nintendocore", alias: "Electronic" },
  { id: 952, name: "Nl Folk", alias: "Folk" },
  { id: 953, name: "No Wave", alias: "Indie Rock" },
  { id: 954, name: "Noise", alias: "Indie Rock" },
  { id: 955, name: "Noise Pop", alias: "Indie Rock" },
  { id: 956, name: "Noise Punk", alias: "Punk" },
  { id: 957, name: "Noise Rock", alias: "Rock" },
  { id: 958, name: "Nordic Folk", alias: "Folk" },
  { id: 959, name: "Nordic House", alias: "Dance" },
  { id: 960, name: "Norteno", alias: "Other" },
  { id: 961, name: "Northern Irish Indie", alias: "Indie Rock" },
  { id: 962, name: "Northern Soul", alias: "Soul" },
  { id: 963, name: "Norwegian Gospel", alias: "Other" },
  { id: 964, name: "Norwegian Hip Hop", alias: "Hip Hop" },
  { id: 965, name: "Norwegian Jazz", alias: "Jazz" },
  { id: 966, name: "Norwegian Metal", alias: "Metal" },
  { id: 967, name: "Norwegian Pop", alias: "Pop" },
  { id: 968, name: "Norwegian Punk", alias: "Punk" },
  { id: 969, name: "Norwegian Rock", alias: "Rock" },
  { id: 970, name: "Nu Age", alias: "Ambient" },
  { id: 971, name: "Nu Disco", alias: "Disco" },
  { id: 972, name: "Nu Electro", alias: "Electronic" },
  { id: 973, name: "Nu Gaze", alias: "Indie Rock" },
  { id: 974, name: "Nu Jazz", alias: "Jazz" },
  { id: 975, name: "Nu Metal", alias: "Metal" },
  { id: 976, name: "Nu Skool Breaks", alias: "Hip Hop" },
  { id: 977, name: "Nu-cumbia", alias: "Dance" },
  { id: 978, name: "Nueva Cancion", alias: "Other" },
  { id: 979, name: "Nursery", alias: "Ambient" },
  { id: 980, name: "Nwobhm", alias: "Metal" },
  { id: 981, name: "Nwothm", alias: "Metal" },
  { id: 982, name: "Nz Indie", alias: "Indie Rock" },
  { id: 983, name: "Oi", alias: "Punk" },
  { id: 984, name: "Old School Hip Hop", alias: "Hip Hop" },
  { id: 985, name: "Old-time", alias: "Folk" },
  { id: 986, name: "Opera", alias: "Classical" },
  { id: 987, name: "Operatic Pop", alias: "Classical" },
  { id: 988, name: "Opm", alias: "Pop" },
  { id: 989, name: "Oratory", alias: "Jazz" },
  { id: 990, name: "Orchestral", alias: "Classical" },
  { id: 991, name: "Organic Ambient", alias: "Ambient" },
  { id: 992, name: "Orgcore", alias: "Punk" },
  { id: 993, name: "Orquesta Tipica", alias: "Tropical" },
  { id: 994, name: "Orquesta Tropical", alias: "Tropical" },
  { id: 995, name: "Oshare Kei", alias: "Pop" },
  { id: 996, name: "Ostrock", alias: "Rock" },
  { id: 997, name: "Outer Hip Hop", alias: "Hip Hop" },
  { id: 998, name: "Outlaw Country", alias: "Country" },
  { id: 999, name: "Outsider", alias: "Alternative Rock" },
  { id: 1000, name: "Outsider House", alias: "Dance" },
  { id: 1001, name: "P Funk", alias: "Funk" },
  { id: 1002, name: "Pagan Black Metal", alias: "Metal" },
  { id: 1003, name: "Pagode", alias: "Other" },
  { id: 1004, name: "Pakistani Pop", alias: "Pop" },
  { id: 1005, name: "Permanent Wave", alias: "Alternative Rock" },
  { id: 1006, name: "Persian Pop", alias: "Pop" },
  { id: 1007, name: "Persian Traditional", alias: "Other" },
  { id: 1008, name: "Perth Indie", alias: "Indie Rock" },
  { id: 1009, name: "Peruvian Rock", alias: "Rock" },
  { id: 1010, name: "Piano Blues", alias: "Blues" },
  { id: 1011, name: "Piano Rock", alias: "Rock" },
  { id: 1012, name: "Piedmont Blues", alias: "Blues" },
  { id: 1013, name: "Pipe Band", alias: "Folk" },
  { id: 1014, name: "Poetry", alias: "Other" },
  { id: 1015, name: "Polish Hip Hop", alias: "Hip Hop" },
  { id: 1016, name: "Polish Indie", alias: "Indie Rock" },
  { id: 1017, name: "Polish Jazz", alias: "Jazz" },
  { id: 1018, name: "Polish Pop", alias: "Pop" },
  { id: 1019, name: "Polish Punk", alias: "Punk" },
  { id: 1020, name: "Polish Reggae", alias: "Reggae" },
  { id: 1021, name: "Polka", alias: "Folk" },
  { id: 1022, name: "Polynesian Pop", alias: "Pop" },
  { id: 1023, name: "Polyphony", alias: "Chill" },
  { id: 1024, name: "Pop", alias: "Pop" },
  { id: 1025, name: "Pop Christmas", alias: "Pop" },
  { id: 1026, name: "Pop Emo", alias: "Emo" },
  { id: 1027, name: "Pop House", alias: "Dance" },
  { id: 1028, name: "Pop Punk", alias: "Punk" },
  { id: 1029, name: "Pop Rap", alias: "Rap" },
  { id: 1030, name: "Pop Rock", alias: "Rock" },
  { id: 1031, name: "Popgaze", alias: "Indie Rock" },
  { id: 1032, name: "Porro", alias: "Other" },
  { id: 1033, name: "Portland Indie", alias: "Indie Rock" },
  { id: 1034, name: "Portuguese Pop", alias: "Pop" },
  { id: 1035, name: "Portuguese Rock", alias: "Rock" },
  { id: 1036, name: "Post Rock", alias: "Indie Rock" },
  { id: 1037, name: "Post-disco", alias: "Disco" },
  { id: 1038, name: "Post-disco Soul", alias: "Soul" },
  { id: 1039, name: "Post-grunge", alias: "Grunge" },
  { id: 1040, name: "Post-hardcore", alias: "Punk" },
  { id: 1041, name: "Post-metal", alias: "Metal" },
  { id: 1042, name: "Post-post-hardcore", alias: "Punk" },
  { id: 1043, name: "Post-punk", alias: "Punk" },
  { id: 1044, name: "Power Blues-rock", alias: "Blues" },
  { id: 1045, name: "Power Electronics", alias: "Electronic" },
  { id: 1046, name: "Power Metal", alias: "Metal" },
  { id: 1047, name: "Power Noise", alias: "Grunge" },
  { id: 1048, name: "Power Pop", alias: "Indie Rock" },
  { id: 1049, name: "Power Violence", alias: "Punk" },
  { id: 1050, name: "Power-pop Punk", alias: "Punk" },
  { id: 1051, name: "Praise", alias: "Other" },
  { id: 1052, name: "Progressive Alternative", alias: "Alternative Rock" },
  { id: 1053, name: "Progressive Bluegrass", alias: "Blues" },
  { id: 1054, name: "Progressive Electro House", alias: "Electronic" },
  { id: 1055, name: "Progressive House", alias: "Dance" },
  { id: 1056, name: "Progressive Metal", alias: "Metal" },
  { id: 1057, name: "Progressive Psytrance", alias: "Electronic" },
  { id: 1058, name: "Progressive Rock", alias: "Rock" },
  { id: 1059, name: "Progressive Trance", alias: "Electronic" },
  { id: 1060, name: "Progressive Trance House", alias: "Electronic" },
  { id: 1061, name: "Progressive Uplifting Trance", alias: "Electronic" },
  { id: 1062, name: "Protopunk", alias: "Punk" },
  { id: 1063, name: "Psych Gaze", alias: "Indie Rock" },
  { id: 1064, name: "Psychedelic Blues-rock", alias: "Blues" },
  { id: 1065, name: "Psychedelic Rock", alias: "Rock" },
  { id: 1066, name: "Psychedelic Trance", alias: "Electronic" },
  { id: 1067, name: "Psychill", alias: "Chill" },
  { id: 1068, name: "Psychobilly", alias: "Punk" },
  { id: 1069, name: "Pub Rock", alias: "Rock" },
  { id: 1070, name: "Puerto Rican Rock", alias: "Rock" },
  { id: 1071, name: "Punjabi", alias: "Other" },
  { id: 1072, name: "Punk", alias: "Punk" },
  { id: 1073, name: "Punk Blues", alias: "Blues" },
  { id: 1074, name: "Punk Christmas", alias: "Punk" },
  { id: 1075, name: "Punk Ska", alias: "Punk" },
  { id: 1076, name: "Qawwali", alias: "Ambient" },
  { id: 1077, name: "Quebecois", alias: "Pop" },
  { id: 1078, name: "Quiet Storm", alias: "Hip Hop" },
  { id: 1079, name: "R&b", alias: "R&B" },
  { id: 1080, name: "Ragga Jungle", alias: "Reggae" },
  { id: 1081, name: "Ragtime", alias: "Other" },
  { id: 1082, name: "Rai", alias: "Indie Pop" },
  { id: 1083, name: "Ranchera", alias: "Other" },
  { id: 1084, name: "Rap", alias: "Rap" },
  { id: 1085, name: "Rap Metal", alias: "Metal" },
  { id: 1086, name: "Rap Metalcore", alias: "R&B" },
  { id: 1087, name: "Rap Rock", alias: "Rock" },
  { id: 1088, name: "Raw Black Metal", alias: "Metal" },
  { id: 1089, name: "Re:techno", alias: "Electronic" },
  { id: 1090, name: "Reading", alias: "Ambient" },
  { id: 1091, name: "Rebetiko", alias: "Folk" },
  { id: 1092, name: "Reggae", alias: "Reggae" },
  { id: 1093, name: "Reggae Fusion", alias: "Reggae" },
  { id: 1094, name: "Reggae Rock", alias: "Reggae" },
  { id: 1095, name: "Reggaeton", alias: "Reggae" },
  { id: 1096, name: "Regional Mexican", alias: "Other" },
  { id: 1097, name: "Relaxative", alias: "Soul" },
  { id: 1098, name: "Remix", alias: "Electronic" },
  { id: 1099, name: "Renaissance", alias: "Other" },
  { id: 1100, name: "Retro Electro", alias: "Electronic" },
  { id: 1101, name: "Retro Metal", alias: "Metal" },
  { id: 1102, name: "Rhythm And Boogie", alias: "Tropical" },
  { id: 1103, name: "Riddim", alias: "Tropical" },
  { id: 1104, name: "Rio De La Plata", alias: "Dance" },
  { id: 1105, name: "Riot Grrrl", alias: "Punk" },
  { id: 1106, name: "Rock", alias: "Rock" },
  { id: 1107, name: "Rock Catala", alias: "Rock" },
  { id: 1108, name: "Rock En Espanol", alias: "Rock" },
  { id: 1109, name: "Rock Gaucho", alias: "Rock" },
  { id: 1110, name: "Rock Noise", alias: "Rock" },
  { id: 1111, name: "Rock Steady", alias: "Rock" },
  { id: 1112, name: "Rock-and-roll", alias: "Rock" },
  { id: 1113, name: "Rockabilly", alias: "Country" },
  { id: 1114, name: "Romanian Pop", alias: "Pop" },
  { id: 1115, name: "Romanian Rock", alias: "Rock" },
  { id: 1116, name: "Romantic", alias: "Classical" },
  { id: 1117, name: "Roots Reggae", alias: "Reggae" },
  { id: 1118, name: "Roots Rock", alias: "Rock" },
  { id: 1119, name: "Rumba", alias: "Tropical" },
  { id: 1120, name: "Russian Alternative", alias: "Chill" },
  { id: 1121, name: "Russian Hip Hop", alias: "Jazz" },
  { id: 1122, name: "Russian Pop", alias: "Pop" },
  { id: 1123, name: "Russian Punk", alias: "Punk" },
  { id: 1124, name: "Russian Rock", alias: "Rock" },
  { id: 1125, name: "Rva Indie", alias: "Indie Rock" },
  { id: 1126, name: "Salsa", alias: "Other" },
  { id: 1127, name: "Salsa International", alias: "Other" },
  { id: 1128, name: "Samba", alias: "Other" },
  { id: 1129, name: "Saxophone", alias: "Jazz" },
  { id: 1130, name: "Schlager", alias: "Pop" },
  { id: 1131, name: "Schranz", alias: "Dance" },
  { id: 1132, name: "Scorecore", alias: "Electronic" },
  { id: 1133, name: "Scottish Rock", alias: "Rock" },
  { id: 1134, name: "Scratch", alias: "Hip Hop" },
  { id: 1135, name: "Screamo", alias: "Alternative Rock" },
  { id: 1136, name: "Screamo Punk", alias: "Alternative Rock" },
  { id: 1137, name: "Screamocore", alias: "Alternative Rock" },
  { id: 1138, name: "Seattle Indie", alias: "Indie Rock" },
  { id: 1139, name: "Sega", alias: "Electronic" },
  { id: 1140, name: "Serialism", alias: "Other" },
  { id: 1141, name: "Sertanejo", alias: "Country" },
  { id: 1142, name: "Sertanejo Tradicional", alias: "Country" },
  { id: 1143, name: "Sertanejo Universitario", alias: "Country" },
  { id: 1144, name: "Sevdah", alias: "Other" },
  { id: 1145, name: "Shanty", alias: "Folk" },
  { id: 1146, name: "Sheffield Indie", alias: "Indie Rock" },
  { id: 1147, name: "Shibuya-kei", alias: "Pop" },
  { id: 1148, name: "Shimmer Pop", alias: "Pop" },
  { id: 1149, name: "Shimmer Psych", alias: "Psychedelic" },
  { id: 1150, name: "Shiver Pop", alias: "Pop" },
  { id: 1151, name: "Shoegaze", alias: "Indie Rock" },
  { id: 1152, name: "Show Tunes", alias: "Pop" },
  { id: 1153, name: "Singaporean Pop", alias: "Pop" },
  { id: 1154, name: "Singer-songwriter", alias: "Indie Rock" },
  { id: 1155, name: "Sinhala", alias: "Other" },
  { id: 1156, name: "Ska", alias: "Punk" },
  { id: 1157, name: "Ska Punk", alias: "Punk" },
  { id: 1158, name: "Ska Revival", alias: "Punk" },
  { id: 1159, name: "Skate Punk", alias: "Punk" },
  { id: 1160, name: "Skiffle", alias: "Folk" },
  { id: 1161, name: "Skinhead Oi", alias: "Punk" },
  { id: 1162, name: "Skinhead Reggae", alias: "Reggae" },
  { id: 1163, name: "Skweee", alias: "Dance" },
  { id: 1164, name: "Slam Death Metal", alias: "Metal" },
  { id: 1165, name: "Slash Punk", alias: "Punk" },
  { id: 1166, name: "Slc Indie", alias: "Indie Rock" },
  { id: 1167, name: "Sleaze Rock", alias: "Rock" },
  { id: 1168, name: "Sleep", alias: "Ambient" },
  { id: 1169, name: "Slovak Hip Hop", alias: "Hip Hop" },
  { id: 1170, name: "Slovak Pop", alias: "Pop" },
  { id: 1171, name: "Slovenian Rock", alias: "Rock" },
  { id: 1172, name: "Slow Core", alias: "Alternative Rock" },
  { id: 1173, name: "Sludge Metal", alias: "Metal" },
  { id: 1174, name: "Smooth Jazz", alias: "Jazz" },
  { id: 1175, name: "Smooth Urban R&b", alias: "R&B" },
  { id: 1176, name: "Soca", alias: "Other" },
  { id: 1177, name: "Soda Pop", alias: "Pop" },
  { id: 1178, name: "Soft Rock", alias: "Rock" },
  { id: 1179, name: "Solipsynthm", alias: "Electronic" },
  { id: 1180, name: "Soukous", alias: "Other" },
  { id: 1181, name: "Soul", alias: "Soul" },
  { id: 1182, name: "Soul Blues", alias: "Blues" },
  { id: 1183, name: "Soul Christmas", alias: "Other" },
  { id: 1184, name: "Soul Flow", alias: "Soul" },
  { id: 1185, name: "Soul Jazz", alias: "Jazz" },
  { id: 1186, name: "Soundtrack", alias: "Other" },
  { id: 1187, name: "South African Jazz", alias: "Jazz" },
  { id: 1188, name: "Southern Gospel", alias: "Soul" },
  { id: 1189, name: "Southern Hip Hop", alias: "Hip Hop" },
  { id: 1190, name: "Southern Rock", alias: "Rock" },
  { id: 1191, name: "Southern Soul", alias: "Soul" },
  { id: 1192, name: "Southern Soul Blues", alias: "Blues" },
  { id: 1193, name: "Space Rock", alias: "Psychedelic" },
  { id: 1194, name: "Spanish Classical", alias: "Classical" },
  { id: 1195, name: "Spanish Folk", alias: "Folk" },
  { id: 1196, name: "Spanish Hip Hop", alias: "Hip Hop" },
  { id: 1197, name: "Spanish Indie Pop", alias: "Indie Pop" },
  { id: 1198, name: "Spanish Indie Rock", alias: "Indie Rock" },
  { id: 1199, name: "Spanish Invasion", alias: "Rock" },
  { id: 1200, name: "Spanish New Wave", alias: "Rock" },
  { id: 1201, name: "Spanish Pop", alias: "Pop" },
  { id: 1202, name: "Spanish Pop Rock", alias: "Pop Rock" },
  { id: 1203, name: "Spanish Punk", alias: "Punk" },
  { id: 1204, name: "Spanish Reggae", alias: "Reggae" },
  { id: 1205, name: "Speed Garage", alias: "Grunge" },
  { id: 1206, name: "Speed Metal", alias: "Metal" },
  { id: 1207, name: "Speedcore", alias: "Electronic" },
  { id: 1208, name: "Spoken Word", alias: "Ambient" },
  { id: 1209, name: "Spytrack", alias: "Electronic" },
  { id: 1210, name: "Steampunk", alias: "Alternative Rock" },
  { id: 1211, name: "Steelpan", alias: "Other" },
  { id: 1212, name: "Stl Indie", alias: "Indie Rock" },
  { id: 1213, name: "Stomp And Flutter", alias: "Folk" },
  { id: 1214, name: "Stomp And Holler", alias: "Folk" },
  { id: 1215, name: "Stomp And Whittle", alias: "Folk" },
  { id: 1216, name: "Stomp Pop", alias: "Pop" },
  { id: 1217, name: "Stoner Metal", alias: "Metal" },
  { id: 1218, name: "Stoner Rock", alias: "Rock" },
  { id: 1219, name: "Straight Edge", alias: "Rock" },
  { id: 1220, name: "Street Punk", alias: "Punk" },
  { id: 1221, name: "Stride", alias: "Jazz" },
  { id: 1222, name: "String Band", alias: "Folk" },
  { id: 1223, name: "String Folk", alias: "Folk" },
  { id: 1224, name: "String Quartet", alias: "Classical" },
  { id: 1225, name: "Substep", alias: "Electronic" },
  { id: 1226, name: "Sunset Lounge", alias: "Chill" },
  { id: 1227, name: "Suomi Rock", alias: "Rock" },
  { id: 1228, name: "Surf Music", alias: "Rock" },
  { id: 1229, name: "Swamp Blues", alias: "Blues" },
  { id: 1230, name: "Swamp Pop", alias: "Pop" },
  { id: 1231, name: "Swedish Alternative Rock", alias: "Alternative Rock" },
  { id: 1232, name: "Swedish Hard Rock", alias: "Rock" },
  { id: 1233, name: "Swedish Hip Hop", alias: "Hip Hop" },
  { id: 1234, name: "Swedish Indie Pop", alias: "Indie Pop" },
  { id: 1235, name: "Swedish Indie Rock", alias: "Indie Rock" },
  { id: 1236, name: "Swedish Jazz", alias: "Jazz" },
  { id: 1237, name: "Swedish Metal", alias: "Metal" },
  { id: 1238, name: "Swedish Pop", alias: "Pop" },
  { id: 1239, name: "Swedish Pop Punk", alias: "Pop Punk" },
  { id: 1240, name: "Swedish Prog", alias: "Progressive Rock" },
  { id: 1241, name: "Swedish Punk", alias: "Punk" },
  { id: 1242, name: "Swedish Reggae", alias: "Reggae" },
  { id: 1243, name: "Swedish Soft Pop", alias: "Pop" },
  { id: 1244, name: "Swedish Synthpop", alias: "Indie Pop" },
  { id: 1245, name: "Swing", alias: "Jazz" },
  { id: 1246, name: "Swirl Psych", alias: "Psychedelic" },
  { id: 1247, name: "Swiss Folk", alias: "Folk" },
  { id: 1248, name: "Swiss Hip Hop", alias: "Hip Hop" },
  { id: 1249, name: "Swiss Rock", alias: "Rock" },
  { id: 1250, name: "Symphonic Black Metal", alias: "Metal" },
  { id: 1251, name: "Symphonic Metal", alias: "Metal" },
  { id: 1252, name: "Symphonic Rock", alias: "Rock" },
  { id: 1253, name: "Synthpop", alias: "Indie Pop" },
  { id: 1254, name: "Taiwanese Pop", alias: "Pop" },
  { id: 1255, name: "Talent Show", alias: "Other" },
  { id: 1256, name: "Tango", alias: "Other" },
  { id: 1257, name: "Tech House", alias: "Electronic" },
  { id: 1258, name: "Technical Brutal Death Metal", alias: "Metal" },
  { id: 1259, name: "Technical Death Metal", alias: "Metal" },
  { id: 1260, name: "Techno", alias: "Electronic" },
  { id: 1261, name: "Teen Pop", alias: "Pop" },
  { id: 1262, name: "Tejano", alias: "Country" },
  { id: 1263, name: "Tekno", alias: "Other" },
  { id: 1264, name: "Terrorcore", alias: "Other" },
  { id: 1265, name: "Texas Blues", alias: "Blues" },
  { id: 1266, name: "Texas Country", alias: "Country" },
  { id: 1267, name: "Thai Indie", alias: "Indie Rock" },
  { id: 1268, name: "Thai Pop", alias: "Pop" },
  { id: 1269, name: "Thrash Core", alias: "Punk" },
  { id: 1270, name: "Thrash Metal", alias: "Metal" },
  { id: 1271, name: "Thrash-groove Metal", alias: "Metal" },
  { id: 1272, name: "Throat Singing", alias: "Other" },
  { id: 1273, name: "Tibetan", alias: "Other" },
  { id: 1274, name: "Tico", alias: "Other" },
  { id: 1275, name: "Timba", alias: "Other" },
  { id: 1276, name: "Tin Pan Alley", alias: "Other" },
  { id: 1277, name: "Traditional Blues", alias: "Blues" },
  { id: 1278, name: "Traditional British Folk", alias: "Folk" },
  { id: 1279, name: "Traditional Country", alias: "Country" },
  { id: 1280, name: "Traditional Folk", alias: "Folk" },
  { id: 1281, name: "Traditional Funk", alias: "Funk" },
  { id: 1282, name: "Traditional Irish Folk", alias: "Folk" },
  { id: 1283, name: "Traditional Reggae", alias: "Reggae" },
  { id: 1284, name: "Traditional Rock 'N Roll", alias: "Rock" },
  { id: 1285, name: "Traditional Rockabilly", alias: "Country" },
  { id: 1286, name: "Traditional Scottish Folk", alias: "Folk" },
  { id: 1287, name: "Traditional Ska", alias: "Punk" },
  { id: 1288, name: "Traditional Soul", alias: "Soul" },
  { id: 1289, name: "Traditional Swing", alias: "Jazz" },
  { id: 1290, name: "Trance", alias: "Electronic" },
  { id: 1291, name: "Trance Hop", alias: "Hip Hop" },
  { id: 1292, name: "Trap Music", alias: "Hip Hop" },
  { id: 1293, name: "Trapstep", alias: "Electronic" },
  { id: 1294, name: "Trash Rock", alias: "Rock" },
  { id: 1295, name: "Triangle Indie", alias: "Indie Rock" },
  { id: 1296, name: "Tribal House", alias: "Dance" },
  { id: 1297, name: "Tribute", alias: "Other" },
  { id: 1298, name: "Trip Hop", alias: "Dance" },
  { id: 1299, name: "Tropical", alias: "Tropical" },
  { id: 1300, name: "Trova", alias: "Other" },
  { id: 1301, name: "Turbo Folk", alias: "Folk" },
  { id: 1302, name: "Turkish Alternative", alias: "Alternative Rock" },
  { id: 1303, name: "Turkish Classical", alias: "Classical" },
  { id: 1304, name: "Turkish Folk", alias: "Folk" },
  { id: 1305, name: "Turkish Hip Hop", alias: "Hip Hop" },
  { id: 1306, name: "Turkish Jazz", alias: "Jazz" },
  { id: 1307, name: "Turkish Pop", alias: "Pop" },
  { id: 1308, name: "Turkish Rock", alias: "Rock" },
  { id: 1309, name: "Turntablism", alias: "Other" },
  { id: 1310, name: "Twee Indie Pop", alias: "Indie Pop" },
  { id: 1311, name: "Twee Pop", alias: "Folk" },
  { id: 1312, name: "Twin Cities Indie", alias: "Indie Rock" },
  { id: 1313, name: "Tzadik", alias: "Other" },
  { id: 1314, name: "Uk Dub", alias: "Dance" },
  { id: 1315, name: "Uk Garage", alias: "Grunge" },
  { id: 1316, name: "Uk Hip Hop", alias: "Hip Hop" },
  { id: 1317, name: "Uk Post-punk", alias: "Alternative Rock" },
  { id: 1318, name: "Ukrainian Rock", alias: "Rock" },
  { id: 1319, name: "Ukulele", alias: "Folk" },
  { id: 1320, name: "Unblack Metal", alias: "Metal" },
  { id: 1321, name: "Underground Hip Hop", alias: "Hip Hop" },
  {
    id: 1322,
    name: "Underground Latin Hip Hop",
    alias: "Hip Hop",
  },
  { id: 1323, name: "Underground Pop Rap", alias: "Rap" },
  { id: 1324, name: "Underground Power Pop", alias: "Rock" },
  { id: 1325, name: "Underground Rap", alias: "Rap" },
  { id: 1326, name: "Uplifting Trance", alias: "Electronic" },
  { id: 1327, name: "Urban Contemporary", alias: "R&B" },
  { id: 1328, name: "Vallenato", alias: "Other" },
  { id: 1329, name: "Vancouver Indie", alias: "Indie Rock" },
  { id: 1330, name: "Vapor House", alias: "Electronic" },
  { id: 1331, name: "Vaporwave", alias: "Electronic" },
  { id: 1332, name: "Vegan Straight Edge", alias: "Rock" },
  { id: 1333, name: "Vegas Indie", alias: "Indie Rock" },
  { id: 1334, name: "Velha Guarda", alias: "Blues" },
  { id: 1335, name: "Venezuelan Rock", alias: "Rock" },
  { id: 1336, name: "Video Game Music", alias: "Game" },
  { id: 1337, name: "Vienna Indie", alias: "Indie Rock" },
  { id: 1338, name: "Vietnamese Pop", alias: "Pop" },
  { id: 1339, name: "Viking Metal", alias: "Metal" },
  { id: 1340, name: "Vintage Chanson", alias: "Jazz" },
  { id: 1341, name: "Vintage Country Folk", alias: "Folk" },
  { id: 1342, name: "Vintage Gospel", alias: "Soul" },
  { id: 1343, name: "Vintage Jazz", alias: "Jazz" },
  { id: 1344, name: "Vintage Reggae", alias: "Reggae" },
  { id: 1345, name: "Vintage Rockabilly", alias: "Country" },
  { id: 1346, name: "Vintage Schlager", alias: "Pop" },
  { id: 1347, name: "Vintage Swedish Pop", alias: "Pop" },
  { id: 1348, name: "Vintage Swing", alias: "Jazz" },
  { id: 1349, name: "Vintage Swoon", alias: "Jazz" },
  { id: 1350, name: "Vintage Tango", alias: "Other" },
  { id: 1351, name: "Vintage Western", alias: "Country" },
  { id: 1352, name: "Violin", alias: "Classical" },
  { id: 1353, name: "Viral Pop", alias: "Pop" },
  { id: 1354, name: "Visual Kei", alias: "Pop" },
  { id: 1355, name: "Vocal House", alias: "Electronic" },
  { id: 1356, name: "Vocal Jazz", alias: "Jazz" },
  { id: 1357, name: "Vocaloid", alias: "Electronic" },
  { id: 1358, name: "Volksmusik", alias: "Folk" },
  { id: 1359, name: "Warm Drone", alias: "Ambient" },
  { id: 1360, name: "Welsh Rock", alias: "Rock" },
  { id: 1361, name: "West African Jazz", alias: "Jazz" },
  { id: 1362, name: "West Coast Rap", alias: "Hip Hop" },
  { id: 1363, name: "Western Swing", alias: "Jazz" },
  { id: 1364, name: "Wind Ensemble", alias: "Classical" },
  { id: 1365, name: "Witch House", alias: "Dance" },
  { id: 1366, name: "Wonky", alias: "Dance" },
  { id: 1367, name: "Workout", alias: "Dance" },
  { id: 1368, name: "World", alias: "Other" },
  { id: 1369, name: "World Chill", alias: "Other" },
  { id: 1370, name: "World Christmas", alias: "Other" },
  { id: 1371, name: "World Fusion", alias: "Other" },
  { id: 1372, name: "Worship", alias: "Soul" },
  { id: 1373, name: "Wrestling", alias: "Rock" },
  { id: 1374, name: "Wrock", alias: "Rock" },
  { id: 1375, name: "Ye Ye", alias: "Pop" },
  { id: 1376, name: "Yoik", alias: "Folk" },
  { id: 1377, name: "Yugoslav Rock", alias: "Rock" },
  { id: 1378, name: "Zeuhl", alias: "Rock" },
  { id: 1379, name: "Zillertal", alias: "Other" },
  { id: 1380, name: "Zim", alias: "R&B" },
  { id: 1381, name: "Zolo", alias: "Punk" },
  { id: 1382, name: "Zouglou", alias: "Dance" },
  { id: 1383, name: "Zouk", alias: "Dance" },
  { id: 1384, name: "Zydeco", alias: "Blues" },
  { id: 1385, name: "Bedroom Pop", alias: "Indie Pop" },
  { id: 1385, name: "Hyperpop", alias: "Indie Pop" },
  { id: 1386, name: "Jazz House", alias: "Electronic" },
  { id: 1387, name: "Glitchcore", alias: "Electronic" },
  { id: 1388, name: "Meme Rap", alias: "Rap" },
  { id: 1389, name: "Viral Rap", alias: "Rap" },
  { id: 1390, name: "Otacore", alias: "Game" },
  { id: 1391, name: "Cloud Rap", alias: "Rap" },
  { id: 1392, name: "Psychedelic Hip Hop", alias: "Hip Hop" },
  { id: 1393, name: "Ohio Indie", alias: "Indie Rock" },
  { id: 1394, name: "Dayton Indie", alias: "Indie Rock" },
  { id: 1395, name: "Oxford Indie", alias: "Indie Rock" },
  { id: 1396, name: "Drain", alias: "Hip Hop" },
  { id: 1397, name: "Virginia Hip Hop", alias: "Hip Hop" },
  { id: 1398, name: "Modern Power Pop", alias: "Indie Rock" },
  { id: 1399, name: "Tape Club", alias: "Indie Rock" },
  { id: 1400, name: "Art Punk", alias: "Punk" },
  { id: 1401, name: "Beatlesque", alias: "Indie Rock" },
  { id: 1402, name: "uk post-punk revival", alias: "Punk" },
  { id: 1403, name: "rhythm game", alias: "Game" },
  { id: 1403, name: "gen z singer-songwriter", alias: "Pop" },
  { id: 1404, name: "Electronic Djent", alias: "Electronic" },
  { id: 1405, name: "Dark Trap", alias: "Rap" },
  { id: 1406, name: "Scottish Indie", alias: "Indie Rock" },
  { id: 1407, name: "pluggnb", alias: "R&B" },
  { id: 1408, name: "singer-songwriter pop", alias: "Indie Pop" },
  { id: 1409, name: "grand rapids indie", alias: "Indie Rock" },
  { id: 1410, name: "swedish garage rock", alias: "Rock" },
  { id: 1411, name: "baroque pop", alias: "Rock" },
  { id: 1412, name: "sad rap", alias: "Rap" },
  { id: 1413, name: "la pop", alias: "Pop" },
  { id: 1414, name: "glitchbreak", alias: "Electronic" },
  { id: 1415, name: "new orleans rap", alias: "Rap" },
  { id: 1416, name: "indie game soundtrack", alias: "Game" },
  { id: 1417, name: "midwest emo", alias: "Emo" },
  { id: 1418, name: "garage rock revival", alias: "Rock" },
  { id: 1419, name: "rage rap", alias: "Rap" },
  { id: 1420, name: "countrygaze", alias: "Country" },
  { id: 1421, name: "pixel", alias: "Game" },
  { id: 1422, name: "modern rock", alias: "Rock" },
  { id: 1423, name: "Chicago Rap", alias: "Rap" },
  { id: 1424, name: "small room", alias: "Indie Rock" },
];


export function getBestGenre(genres) {
  for (const genre of genres) {
    const genreObj = allGenres.find(
      (g) => g.name.toLowerCase() === genre.toLowerCase()
    );

    console.log(genres);

    if (genreObj) {
      let bestMatch = null;
      let bestMatchScore = Infinity;

      for (const generalGenre of generalGenres) {
        const matchScore = levenshteinDistance(
          genreObj.alias.toLowerCase(),
          generalGenre.toLowerCase()
        );

        if (matchScore < bestMatchScore) {
          bestMatch = generalGenre;
          bestMatchScore = matchScore;
        }
      }

      if (bestMatch) {
        
        return bestMatch.replace(/ /g, "");
      }
    } else {
      for (const generalGenre of generalGenres) {
        if (genre.toLowerCase().includes(generalGenre.toLowerCase())) {
          console.log(generalGenre);
          return generalGenre.replace(/ /g, "");
        }
      }
    }
  }

  return "Other";
}

function levenshteinDistance(a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
        );
      }
    }
  }

  return matrix[b.length][a.length];
}




const Genres = ({ genres, token }) => {
  const [top3Genres, setTopGenres] = useState([]);

};

export default Genres;
