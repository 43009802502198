import React, { useEffect, useState } from "react";

import "./Home.css";
import { useLocation } from "react-router-dom";

import waveSVG from "./assets/Wave-2.svg";

const wave = document.getElementsByClassName("wave");
const content = document.getElementsByClassName("container");
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const FadeToggle = ({ fadeState, setFadeState }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const [zindex, setZIndex] = useState(true);
  useEffect(() => {
  }, [fadeState]);

  const toggleFade = () => {
    setFadeState((prevState) => !prevState);
  };

  useEffect(() => {
    setTimeout(() => {
      setZIndex(false);
    }, 300);

    if (isHomePage) {
    }
  }, []);

  return (
    <>
      <object
        className={`wave ${!fadeState && !isHomePage ? "hide" : fadeState ? "animate-wave" : ""
          }`}
        style={zindex ? { zIndex: 20 } : {}}
        alt="Wave"
        data={waveSVG}
        type="image/svg+xml"
      ></object>
    </>
  );

  //   content[0].style.opacity = content[0].style.opacity === "0" ? "1" : "0";
  //   wave[0].style.bottom = wave[0].style.bottom === "-70vh" ? "-180vh" : "-70vh";
  //   wave[0].style.zIndex = wave[0].style.zIndex === "11" ? "11" : "11";
};

// export const fadein = () => {
//   FadeToggle();
//   sleep(500).then(() => {});
// };

export default FadeToggle;
