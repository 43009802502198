import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Info from "../assets/info-solid.svg";
import BurgerMenu from "../assets/burger-menu.svg";
import FadeToggle from "../Fade.jsx";
import catLogo from "../assets/Cat/siteLogo.svg";
import flow from "../assets/flow.webp";

var mobilenavlinks = document.getElementsByClassName("nav__links--mobile");

function Nav({ token, LogOut, userData, getProfileData }) {
  const [modal, setModal] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const [expired, setExpired] = useState(true);

  const [fadeState, setFadeState] = useState(true);

  const toggleAboutModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    setTimeout(() => {
      setFadeState(false);
    }, 300);

  }, []);

  const toggleMobileMenu = () => {
    setMobileNav(!mobileNav);
    setFadeState(true);
    mobilenavlinks[0].style.opacity = 1;
  };

  useEffect(() => {
    if (fadeState && !mobileNav) {
      mobilenavlinks[0].style.opacity = 0;
      setTimeout(() => {
        setFadeState(false)
      }, 300);

    }
  }, [fadeState, mobileNav]);

  const profileImage = (data) => {
    return <img className="profile__image" src={data.images[1].url} alt="" />;
  };

  return (
    <>

      {/* DESKTOP

    NAVIGATION

    SECTION */}
      <nav>
        <div className="nav__links">

          <Link to="/" className="nav__link--anchor link__hover-effect site__logo">
            <img className="pet__logo" src={catLogo} alt="" />
            <h3 className="logo__header">catify.site</h3>
          </Link>

        </div>
        <div className="nav__right">
          <Link
            to="/privacy-policy"
            className="nav__link--anchor link__hover-effect"
          >
            privacy policy
          </Link>
          {token &&
            <>

              <button onClick={(e) => { LogOut(e) }} className="btn logout__btn">
                Log Out
              </button>
              {userData?.display_name && profileImage(userData)}
            </>
          }
          <button
            onClick={() => toggleAboutModal()}
            to="/about"
            className="nav__link--anchor info__button"
          >
            <img className="info__icon" src={Info} alt="Info Icon" />
          </button>
        </div>
      </nav>



      {/* MOBILE 


NAVIGATION 


SECTION */}
      <nav className={`mobile__nav ${fadeState ? "shownav" : ""}`}>
        <div className={`nav__links--mobile ${fadeState ? "show" : "hidden"}`}>

          <Link to="/" onClick={() => toggleMobileMenu()} className="nav__link--anchor link__hover-effect site__logo">
            <img className="pet__logo" src={catLogo} alt="" />
            <h3 className="logo__header">catify.site</h3>
          </Link>


          <Link
            onClick={() => toggleMobileMenu()}
            to="/"
            className="nav__link--anchor link__hover-effect"
          >

            <span className="material-symbols-outlined">home</span> Home
          </Link>
          <Link
            onClick={() => toggleMobileMenu()}
            to="/privacy-policy"
            className="nav__link--anchor link__hover-effect"
          >
            <span className="material-symbols-outlined">policy</span>
            Privacy policy
          </Link>

          <Link to="/about"
            onClick={() => { toggleMobileMenu(); }}

            className="nav__link--anchor"
          >
            <span className="material-symbols-outlined">info</span> About
          </Link>



          {/* {userData && Object.keys(userData).length ? <> */}
          {token ? <>
            <div className="mobile__nav--profile"><button onClick={(e) => { LogOut(e) }} className="btn logout__btn">
              Log Out
            </button>
              {userData?.display_name && profileImage(userData)}
            </div>
            <div className="profile__verified"><p><span className="material-symbols-outlined">verified</span>Spotify connected</p></div>

          </> : (<> <div className="profile__none"><p><span className="material-symbols-outlined">no_accounts</span>No account connected</p></div></>)}
        </div>


        <button
          className="burger__menu--button"
          onClick={() => toggleMobileMenu()}
        >
          <div id="hamburger-1" className={`hamburger ${fadeState ? "open" : ""}`}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </button>
      </nav >

      <FadeToggle fadeState={fadeState} setFadeState={setFadeState} />

      {
        modal && (
          <div className="about__modal">
            <div className="about__container">
              <h2>About Catify</h2>

              <p>designed by <Link to="https://github.com/AWARD220">Alex</Link> and <Link to="https://github.com/WillDoyle">Will</Link></p>
              <div className="about__info">

                <br />

                <p>Catify is a dynamic virtual pet webapp that utilizes Spotify Web API to create beautiful personal pets based on music interests.</p>
                <br />
                <p>Your top <strong>3</strong> spotify artists are sorted by listening hours. Each artist is assigned a generalised genre from a list of over 1386 unique genres. Each artist and their respective genre represents a section of the cat (top, middle, bottom, ascending order).</p>
                <img src={flow} className="flow__img" alt="" />
                <h3>A composite image is made from your chosen pet colour and your top 3 artist's and their corresponding genre</h3>
                <br />
                <p>Sometimes an artist's genre can't be found due to limitations in our list. Not every artist's genre can result in a general genre. Any genres not found are automatically flagged by our system.</p>
                <p>&copy; 2024 Catify</p>
              </div>
              <div
                className="about__close--button"
                onClick={() => toggleAboutModal()}
              >
                <span style={{ color: "#291a08" }} className="material-symbols-outlined">
                  cancel
                </span>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default Nav;
