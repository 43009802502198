import React from "react";
import Pet from "../components/Pet.jsx";
import { useEffect, useState } from "react";
import Genres from "../Genres.jsx";
import axios from "axios";
import { fadetoggle, fadein } from "../Fade.jsx";
import { useNavigate } from "react-router-dom";

import searchTopArtists from "../components/topArtists.jsx";

function Results({ token, expired, setExpired }) {
  const [artistID, setArtistID] = useState("");
  const [topArtists, setTopArtists] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [genres, setGenres] = useState([""]);
  const [artists, setArtists] = useState([]);
  const [tracks, setTracks] = useState([]);

  const navigate = useNavigate();

  const searchTopTracks = async () => {
    try {
      const { data } = await axios.get(
        "https://api.spotify.com/v1/me/top/tracks",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            time_range: "medium_term",
            limit: 5,
          },
        }
      );

      setTracks(data.items);
    } catch (error) {
      console.error("Error fetching top tracks:", error.response);
    }
  };



  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log(token);
  //     if (token.length < 1) {
  //       navigate("/");
  //     }
  //   }, 5000);
  // }, []);

  const getArtistGenre = async (artistID) => {
    try {
      const { data } = await axios.get(
        `https://api.spotify.com/v1/artists/${artistID}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGenres(data.genres);
      console.log(data.genres);
    } catch (error) {
      console.log("Something went wrong! ArtistID: " + artistID);
    }
  };

  const renderArtists = () => {
    return artists.map((artist) => (
      <div key={artist.id} className="spotify__wrapper">
        {artist.images.length ? (
          <img
            alt="placeholder artist"
            width={"50%"}
            src={artist.images[0].url}
          />
        ) : (
          <div>No Image </div>
        )}
        {artist.name}
      </div>
    ));
  };

  const renderTopArtists = () => {
    return topArtists.map((artist) => (
      <div key={artist.id} className="spotify__wrapper">
        <h2>{artist.name}</h2>
        <h2>{artist.genres}</h2>
        <Genres token={token} genres={artist.genres}></Genres>
      </div>
    ));
  };

  const renderTracks = () => {
    return tracks.map((track) => (
      <div key={track.id} className="spotify__wrapper">
        <h2>{track.name}</h2>

        <div className="artist__wrapper">
          {track.artists.map((artist) => (
            <div className="artist" key={artist.id}>
              <p>{artist.name}</p>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <>
      <Pet token={token} setTopArtists={setTopArtists} genres={genres} expired={expired} setExpired={setExpired} />

      <Genres genres={genres} token={token} />
    </>
  );
}

export default Results;
