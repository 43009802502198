import axios from "axios";
import { useEffect, useState } from "react";
import Genres from "../Genres";

const searchTopArtists = async ({ token, timeRange }) => {
  if (token.length > 0) {


    try {
      const { data } = await axios.get(
        `https://api.spotify.com/v1/me/top/artists?time_range=${timeRange}&limit=3`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.items;
    } catch (error) {
      console.error("Error fetching top artists:", error);
    }
  }
};

export default searchTopArtists;
