import React, { createRef, useCallback, useRef } from "react";
import searchTopArtists from "./topArtists.jsx";
import { useEffect, useState } from "react";
import { getBestGenre } from "../Genres.jsx";
import { Puff } from "react-loading-icons";
import dropdown from "../assets/dropdown.svg";


// CAT IMAGES
import catLogo from "../assets/Cat/siteLogo.svg";
import catOutline from "../assets/Cat/CatOutline.svg";

//CAT COLOUR IMPORTS
import catAbyssinian from "../assets/Cat/Cats/CAbyssinian.svg";
import catBlack from "../assets/Cat/Cats/CBlack.svg";
import catCalico from "../assets/Cat/Cats/CCalico.svg";
import catGarfield from "../assets/Cat/Cats/CGarfield.svg";
import catGrey from "../assets/Cat/Cats/CGrey.svg";
import catRagamuffin from "../assets/Cat/Cats/CRagamuffin.svg";
import catTabby from "../assets/Cat/Cats/CTabby.svg";
import catWhite from "../assets/Cat/Cats/CWhite.svg";

import spotifyLogo from "../assets/spotify-logo-long.webp";

import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

//BACKGROUND IMPORTS
import BGYellow from "../assets/BGYellow.svg";
import BGBlue from "../assets/BGBlue.svg";
import BGRed from "../assets/BGRed.svg";
import BGPurple from "../assets/BGPurple.svg";
import BGGreen from "../assets/BGGreen.svg";
import BGOrange from "../assets/BGOrange.svg";
import RenderTopArtists from "./RenderTopArtists.jsx";
import ScreenshotModal from "./ScreenshotModal.jsx";


var BGNum = 0;
var cutomizationTransistion = document.getElementsByClassName("pet__customization--items");

export const Catlist = [
  catAbyssinian,
  catBlack,
  catCalico,
  catGarfield,
  catGrey,
  catRagamuffin,
  catTabby,
  catWhite,
];

const bgList = [
  BGYellow,
  BGRed,
  BGBlue,
  BGPurple,
  BGOrange,
  BGGreen,
];


function Pet({ token, genres, expired }) {
  const [loading, setLoading] = useState(false);
  const [pet__name, setPetName] = useState("");
  const [catImg, setCatImg] = useState("");
  const [bgImg, setBgImg] = useState("");
  const [topArtists, setTopArtists] = useState([]);
  const [showURL, setShowURL] = useState(false);

  //MODALS / MENUS
  const [colourModal, setColourModal] = useState(false);
  const [backgroundModal, setBackgroundModal] = useState(false);
  const [customizationMenu, setCustomizationMenu] = useState(false);
  const [dropdownRotated, setDropdownRotated] = useState(false);
  const customizationRef = useRef(null);

  const [bgNum, setBackground] = useState("");
  const [randomCatNum, setRandomCatNum] = useState(null);
  const [randomBgNum, setRandomBgNum] = useState(null);
  const [catNum, setCatNum] = useState(null);

  const [backgroundButtonsPressed, setBackgroundButtonsPressed] = useState(Array(6).fill(false));
  const [catButtonsPressed, setCatButtonsPressed] = useState(Array(8).fill(false));
  const [timeRangeButtonsPressed, setTimeRangeButtonsPressed] = useState({
    "4weeks": false,
    "6months": false,
    "allTime": false,
  });

  //MOBILE STATES
  const [mobile, setMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [catGenres, setCatGenres] = useState(['Country']);

  //CAT SECTIONS VARIABLES
  const [catT, setCatT] = useState(null);
  const [catM, setCatM] = useState(null);
  const [catB, setCatB] = useState(null);
  const petContainerRef = useRef(null);

  //Pet Modal
  const [petModal, setPetModal] = useState(false);


  const togglePetModal = () => {
    setPetModal(!petModal);
  };


  const downloadButton = useCallback(() => {
    setLoading(true);
    setShowURL(true);
    if (petContainerRef.current === null) {
      return
    }
    toJpeg(petContainerRef.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-pet.jpeg'
        link.href = dataUrl
        link.click()
        setLoading(false);
        setShowURL(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        setShowURL(false);
      });
  }, [petContainerRef])

  const handleShare = async () => {
    setLoading(true);
    const newFile = await toBlob(petContainerRef.current);
    const data = {
      files: [
        new File([newFile], 'my-pet.png', {
          type: newFile.type,
        }),
      ],
      title: 'Image',
      text: 'image',
    };

    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  function changeBackgroundColour(e, index) {
    const imgSrc = e.currentTarget.querySelector('.background__img').getAttribute('src');
    console.log(imgSrc);
    setBgImg(imgSrc);

    setBackgroundButtonsPressed(backgroundButtonsPressed.map((_, i) => i === index));
  }

  function changeCatColour(e, index) {
    const imgSrc = e.currentTarget.querySelector('.colour__img').getAttribute('src');
    //console.log(imgSrc);
    setCatImg(imgSrc);

    setCatButtonsPressed(catButtonsPressed.map((_, i) => i === index));
  }

  useEffect(() => {
    if (catGenres.length >= 3) {
      setLoading(true);
      setTimeout(() => {
        if(catGenres[0] !== "Other"){
        import(`../assets/Cat/${catGenres[0]}/T${catGenres[0]}.svg`).then(
          (catImage) => {
            setCatT(catImage.default);
          }
        );
      }
      if(catGenres[1] !== "Other"){

        import(`../assets/Cat/${catGenres[1]}/M${catGenres[1]}.svg`).then(
          (catImage) => {
            setCatM(catImage.default);
          }
        );
      }
      if(catGenres[2] !== "Other"){


        import(`../assets/Cat/${catGenres[2]}/B${catGenres[2]}.svg`).then(
          (catImage) => {
            setCatB(catImage.default);
          }
        );
      }
        setLoading(false);
      }, 500);
    }
  }, [catGenres]);


  const handleTimeRangeClick = async (timeRange) => {
    try {
      // Update button pressed state
      setTimeRangeButtonsPressed({
        "4weeks": timeRange === "short_term",
        "6months": timeRange === "medium_term",
        "allTime": timeRange === "long_term",
      });
      const artists = await searchTopArtists({ token, timeRange });
      setTopArtists(artists);
      setCatGenres([]);

      // Use Promise.all to wait for all async operations to complete
      await Promise.all(artists.map(async (artist) => {
        const artistGenres = [];
        artist.genres.forEach((genre) => {
          artistGenres.push(genre);
        });
        
        const bestGenre = await getBestGenre(artistGenres);
        
        setCatGenres((prevGenres) => [...prevGenres, bestGenre]);
      }));
    } catch (error) {
      console.error("Error fetching artists:", error);
    }
  };

  useEffect(() => {
    // console.log(genres);
    randomCat();
    changeBackground();
  }, [genres]);

  useEffect(() => {
    if(token.length > 0){
     
      handleTimeRangeClick("short_term"); // Set the default time range to "short_term"
    }
  
  }, [token])


  useEffect(() => {

    const handleResize = () => {
      // Update the state with the new window width
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    if (windowWidth <= 1025) {
      setMobile(true);
    }
    else {
      setMobile(false);
    }
  })

  useEffect(() => {
    togglePetModal();
    const customizationElement = customizationRef.current;

    if (customizationElement) {
      if (customizationMenu) {
        customizationElement.classList.add('show');
      } else {
        customizationElement.classList.remove('show');
      }
    }


  }, [customizationMenu]);

  function changeBackground() {
    setRandomBgNum(BGNum = Math.floor(Math.random() * 6));
  };

  function randomCat() {
    setCatImg(Catlist[Math.floor(Math.random() * 7)]);
  }

  function toggleColourModal() {
    setColourModal(!colourModal);
  }

  function toggleBackgroundModal() {
  }

  function showSiteURL() {

  }

  function toggleCustomizationMenu() {
    setDropdownRotated(!dropdownRotated);

    if (customizationMenu === true) {
      cutomizationTransistion[0].style.maxHeight = "0vh";
      setTimeout(() => {
        setCustomizationMenu(!customizationMenu);
      }, 300);
    }
    else {
      cutomizationTransistion[0].style.maxHeight = "100vh";
      setCustomizationMenu(!customizationMenu);
    }
  }
  //onClick={() => togglePetModal()
  return (
    <>
      {" "}

      <section id="pet">
        {petModal && <ScreenshotModal petContainerRef={petContainerRef} bgImg={bgImg} bgList={bgList} randomBgNum={randomBgNum} catT={catT} catM={catM} catB={catB} catOutline={catOutline} catImg={catImg} showURL={showURL} catLogo={catLogo} topArtists={topArtists} spotifyLogo={spotifyLogo} petModal={petModal} setPetModal={setPetModal} downloadButton={downloadButton} />}
        <div className="pet__wrapper">
          {token ? (
            <>
              <div className="pet__container" id="pet__container">
                <button className="refresh__button download__button" onClick={() => downloadButton()}>
                  <span className="material-symbols-outlined">
                    download
                  </span>
                </button>

                <button className="refresh__button share__button" onClick={handleShare}>
                  <span className="material-symbols-outlined">
                    share
                  </span>
                </button>
                {loading && (
                  <>
                    <Puff
                      stroke="#98ff98"

                      style={{
                        position: "absolute",
                        zIndex: 5,
                        height: "30%",
                        width: "30%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    <div className="loading__background"></div>
                  </>
                )}

                {/* If pet__name has a length (a value), then show h3 */}
                <div className="pet__object" style={{ backgroundImage: `url(${bgImg ? bgImg : bgList[randomBgNum]})` }} >
                  <div className="pet__object--container">
                    <div className="pet__img--container">
                      <img className="pet__img pet__img--t" draggable="false" id="pet__object" src={catT} alt="" />
                      <img className="pet__img pet__img--m" draggable="false" src={catM} alt="" />
                      <img className="pet__img pet__img--b" draggable="false" src={catB} alt="" />
                      <img className="pet__img pet__img--outline" draggable="false" src={catOutline} alt="" />
                      <img className="pet__img--background" draggable="false" src={catImg} alt="" />
                    </div>
                    <div className="artists__wrapper">
                      <div className="following__artists--container">
                        <h3 className="following__artists--dynamic">Inspired by top artists:</h3>
                      </div>
                      <RenderTopArtists artists={topArtists} />
                    </div>

                  </div>

                  <img className="spotify__pet" src={spotifyLogo} alt="" />
                </div>
              </div>
              <div className={`pet__customization`} id="pet__customization" ref={customizationRef}
                onClick={mobile ? () => toggleCustomizationMenu() : null}>
                <div id="customization__h1">
                  <h1>Customization Options</h1>
                  <object alt="" data={dropdown} className={`icon ${dropdownRotated ? 'rotated' : ''}`} type="image/svg+xml" id="dropdown" ></object>
                </div>
                <div className={`pet__customization--items`}>
                  {!mobile ? (
                    <div className={`customization__wrapper`}>
                      <div className={`pet__timeline `}>
                        <h3>Include artists from</h3>
                        <div className={`pet__choice `}>
                          <div>
                            <button
                              id="4weeks"
                              className={`timeline__button ${timeRangeButtonsPressed["4weeks"] ? 'timepressed' : ''}`}
                              onClick={() => handleTimeRangeClick("short_term")}
                            > Last 4 weeks
                            </button>
                            <button
                              id="6months"
                              className={`timeline__button ${timeRangeButtonsPressed["6months"] ? 'timepressed' : ''}`}
                              onClick={() => handleTimeRangeClick("medium_term")}
                            >
                              Last 6 months
                            </button>
                          </div>
                          <div>
                            <button
                              id="allTime"
                              className={`timeline__button ${timeRangeButtonsPressed["allTime"] ? 'timepressed' : ''}`}
                              onClick={() => handleTimeRangeClick("long_term")}
                            >
                              All time
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`pet__background `}>
                        <h3>Pet background</h3>
                        <div className={`options__wrapper`} >
                          {bgList.map((bg, index) => (
                            <div key={index} className={`option__wrapper ${backgroundButtonsPressed[index] ? 'pressed' : ''}`}>
                              <div className={`cat__option `}
                                onClick={(e) => changeBackgroundColour(e, index)}
                              >
                                <img src={bg} className="background__img" alt="" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className={`pet__colour`}>
                        <h3 className={``}>Pet colour</h3>
                        <div className={`options__wrapper `}>
                          {Catlist.map((cat, index) => (
                            <div key={index} className={`option__wrapper ${catButtonsPressed[index] ? 'pressed' : ''}`}>
                              <div className="cat__option" onClick={(e) => changeCatColour(e, index)}>
                                <img src={cat} className={`colour__img `} alt="" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    customizationMenu && (
                      <div className={`customization__wrapper`}>
                        <div className={`pet__timeline `}>
                          <h3>Include artists from</h3>
                          <div className={`pet__choice `}>
                            <div>
                              <button
                                id="4weeks"
                                className={`timeline__button ${timeRangeButtonsPressed["4weeks"] ? 'timepressed' : ''}`}
                                onClick={() => handleTimeRangeClick("short_term")}
                              >
                                Last 4 weeks
                              </button>
                              <button
                                id="6months"
                                className={`timeline__button ${timeRangeButtonsPressed["6months"] ? 'timepressed' : ''}`}
                                onClick={() => handleTimeRangeClick("medium_term")}
                              >
                                Last 6 months
                              </button>
                            </div>
                            <div>
                              <button
                                id="allTime"
                                className={`timeline__button ${timeRangeButtonsPressed["allTime"] ? 'timepressed' : ''}`}
                                onClick={() => handleTimeRangeClick("long_term")}
                              >
                                All time
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className={`pet__background `}>
                          <h3>Pet background</h3>      <div className={`options__wrapper`} >
                            {bgList.map((bg, index) => (
                              <div key={index} className={`option__wrapper`}>
                                <div
                                  className={`cat__option ${backgroundButtonsPressed[index] ? 'pressed' : ''}`}
                                  onClick={(e) => changeBackgroundColour(e, index)}
                                >
                                  <img src={bg} className="background__img" alt="" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className={`pet__colour`}>
                          <h3 className={``}>Pet colour</h3>
                          <div className={`options__wrapper `}>
                            {Catlist.map((cat, index) => (
                              <div key={index} className="cat__option" onClick={(e) => changeCatColour(e, index)}>
                                <img src={cat} className={`colour__img ${catButtonsPressed[index] ? 'pressed' : ''}`} alt="" />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          ) : (
            <h2>Sign in to customize</h2>
          )}
        </div>
      </section >
    </>
  );
}

export default Pet;
