import React from 'react'
import { Link } from 'react-router-dom';

const RenderTopArtists = ({ artists }) => {
    //console.log(artists);

    return (
        <>
            {artists?.map((artist) => (
                <Link target='_blank' to={artist.external_urls.spotify} className="artist__wrapper artist__wrapper--download" key={artist.id} >
                    <img src={artist.images[0].url} alt="" />
                    <div>
                        <h3>{artist.name}</h3>
                    </div>
                </Link >
            ))}
        </>
    );
}

export default RenderTopArtists;