import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <section id="privacy">
        <h1>Privacy Policy </h1>
        <p>
          Catify / www.catify.site ("we," "us," or "our") is committed to safeguarding the
          privacy and security of your personal information while using our web
          application, which utilizes the Spotify API.
        </p>
        <br />
        <p>
          By using Catify, you agree to the terms outlined in this Privacy
          Policy. If you do not agree with the terms, please do not use our
          application.
        </p>
        <h2>Information We Collect </h2>
        <hr />
        <h2>Spotify API Data:</h2>
        <p>
          Catify uses the Spotify API to access and retrieve specific
          information from your Spotify account, such as your profile picture,
          name, and listening data like top artists and tracks.
        </p>
        <h2>Session Token: </h2>
        <p>
          Catify only stores a session token for the duration of your active
          session. This token is used to authenticate your interactions with the
          Spotify API during your time on the Catify website.
        </p>

        <h2>Session Management:</h2>
        <p>
          {" "}
          The session token is used for authentication purposes during your
          active session on Catify and is not stored after you log out or close
          the browser.
        </p>
        <h2>Information Sharing</h2>
        <p>
          {" "}
          No Third-Party Sharing: Catify does not share your information with
          any third-party service providers. Your Spotify API data is used
          exclusively within the Catify web application.
        </p>
        <h2>Data storage:</h2>
        <p>
          Catify does not store any of your Spotify data beyond the session
          token during your active use of the website. No personal information
          is retained after you log out or close the browser. Catify does collect data on genres that are not found when a niche genre cannot be generalized. Catify reserves the right to update or modify this
          Privacy Policy at any time.
        </p>
        <br />
        <br />
        <p>
          If you have any questions or concerns about Catify please reach out via email: 19doylew@gmail.com. Thank you for using Catify!
        </p>
      </section>
    </>
  );
}

export default PrivacyPolicy;
