import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  /*return (
    <>
      <div className="footer__wrapper">
        <h4 className="footer__text">
          Designed by{" "}
          <Link to="https://github.com/AWARD220" target="_blank">
            Alex
          </Link>{" "}
          and{" "}
          <Link to="https://github.com/WillDoyle" target="_blank">
            Will
          </Link>
          <br />
        </h4>
        <h4>&copy; 2024</h4>
      </div>
    </>
  );
*/}

export default Footer;
