// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZL6wa36PMUjp_I50Z1Hkl3TpqiKrd2k8",
  authDomain: "petspot4.firebaseapp.com",
  projectId: "petspot4",
  storageBucket: "petspot4.appspot.com",
  messagingSenderId: "642090913143",
  appId: "1:642090913143:web:a0d25dbed6df2beb28a572",
  measurementId: "G-8F3N0FL5DJ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
