import React, { useEffect, useState } from "react";
import "../Home.css";
import wave from "../assets/Wave-2.svg";
import { useNavigate } from "react-router-dom";
import FadeToggle from "../Fade.jsx";
import spotifyLogo from "../assets/spotify-logo.png";
import { Catlist } from "../components/Pet.jsx"
import { generalGenres } from "../Genres.jsx"
import catOutline from "../assets/Cat/CatOutline.svg";
import CatShadow from "../assets/Cat/CatShadow.svg";

const typeLength = Catlist.length;
const genreLength = generalGenres.length;



function Home({ getProfileData, token, LogOut, expired, setExpired, userData, setUserData, LogIn }) {
  const [catT, setCatT] = useState(null);
  const [catM, setCatM] = useState(null);
  const [catB, setCatB] = useState(null);
  const [catC, setCatC] = useState(null);


  const navigate = useNavigate();

  function randomizeCat() {
    const typeLength = Catlist.length;
    const genreLength = generalGenres.length;
    const Tnum = Math.floor(Math.random() * genreLength);
    const Mnum = Math.floor(Math.random() * genreLength);
    const Bnum = Math.floor(Math.random() * genreLength);
    const Cnum = Math.floor(Math.random() * typeLength);
    const promises = [];

    const loadCatImage = (genre, type) => {
      const importPromise = import(`../assets/Cat/${genre.replace(/\s+/g, "")}/${type}${genre.replace(/\s+/g, "")}.svg`)
        .then((catImage) => catImage.default);
      promises.push(importPromise);
      return importPromise;
    };

    // Use Promise.all to wait for all import promises to resolve
    Promise.all([
      loadCatImage(generalGenres[Tnum], "T"),
      loadCatImage(generalGenres[Mnum], "M"),
      loadCatImage(generalGenres[Bnum], "B"),
    ]).then(([catTImage, catMImage, catBImage]) => {
      // Set the state only once all images are loaded
      setCatT(catTImage);
      setCatM(catMImage);
      setCatB(catBImage);
      setCatC(Catlist[Cnum]);
      // Log a message indicating that all images are loaded
      console.log("All cat images loaded");
    });
  }

  useEffect(() => {
    // Check if the token is present before redirecting
    if (Object.keys(token).length === 0) {
      navigate("/"); // Redirect to the home page
    }
    randomizeCat();
    // Set up the interval for randomizing the cat images
    const interval = setInterval(function () {
      randomizeCat();
    }, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);

  }, []);

  return (
    <section id="home">
      <div className="container" id="home__container">
        <div className="row" id="home__row">
          <div className="landing__wrapper">
            <h1 className="landing__header">
              Find your personal music companion
            </h1>
            <h2 className="landing__sub-header">
              based on your Spotify listening data
            </h2>
            <button
              className="btn spotify__button"
              id="login-link"
              onClick={async (e) => { LogOut(e); LogIn(e); }}
            >
              <img src={spotifyLogo} className="spotify__signin" alt="" />
              {userData?.display_name !== undefined
                ? `Signed in as ${userData.display_name.split(' ')[0]}`
                : 'Sign in with Spotify'
              }
            </button>
          </div>
        </div>
        <div className="hero__img">
          <div className="pet__object pet__object--home">
            <img className="pet__img pet__img--t" src={catT} alt="" />
            <img className="pet__img pet__img--m" src={catM} alt="" />
            <img className="pet__img pet__img--b" src={catB} alt="" />
            <img className="pet__img pet__img--outline" src={catOutline} alt="" />
            <img className="pet__img--background" src={catC} alt="" />
            <img className="pet__img--shadow" src={CatShadow} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

/*const wave2 = document.getElementById("wave2").contentDocument;
console.log(wave2)
const paths = wave2.querySelectorAll('path');
console.log(paths)
for (let i = 0; i < paths.length; i++) {
  paths[i].setAttribute('fill', "#D0E5E6");
}*/

export default Home;
