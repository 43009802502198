import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Genres from "./Genres.jsx";
import { Analytics } from '@vercel/analytics/react';


import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

// import Home from "./Home.js";

//Assets
import Home from "./pages/Home.jsx";
import Results from "./pages/Results.jsx";
import Nav from "./components/Nav.jsx";
import About from "./components/About.jsx";
import Footer from "./components/Footer.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";

// Required for side-effects
import "firebase/firestore";

function App() {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;

  //https://petspot-3-p8ce.vercel.app/
  //http://localhost:3000/
  //https://www.catify.site/
  const REDIRECT_URI = "https://www.catify.site/cat";
  const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
  const RESPONSE_TYPE = "token";




  // Stores user token
  const [token, setToken] = useState("");
  const [userData, setUserData] = useState({});

  const [expired, setExpired] = useState(false);

  //When page loads, get token
  useEffect(() => {
    const hash = window.location.hash
    let token = window.localStorage.getItem("token")

    if (!token && hash) {
      token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

      window.location.hash = ""
      window.localStorage.setItem("token", token)
    }

    setToken(token)

  }, [])

  //When token changes, check if token is expired
  useEffect(() => {
    checkExpired();
  }, [token])

  //If token isn't expired, then the user data to display pfp, name, etc...



  const checkExpired = async () => {
    setTimeout(async () => {
      try {

        const { data } = await axios.get("https://api.spotify.com/v1/me", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })


        if (data?.display_name) {
          console.log('Not expired');
          
          getProfileData();
          setExpired(false);
        }
      } catch (error) {
        console.log('Expired');
        if (error.response && error.response.status === 401) {
          setToken("");
          setExpired(true);

        }
      }
    })


  };
  const LogOut = (e) => {
    e.preventDefault();
    setToken("");
    window.localStorage.removeItem("token");
    window.location.reload();
    window.location.href = "/"
  };

  useEffect(() => {
    console.log(userData);
  }, [userData])

  const LogIn = (e) => {
    e.preventDefault();
    window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=user-top-read`;
  }

  const getProfileData = async () => {
    try {
      // Make the API request to fetch user data using the access token
      const { data } = await axios.get(`https://api.spotify.com/v1/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(data);
      
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // Token expired, handle accordingly (e.g., redirect to login)
        setToken("");
        setExpired(true);
        window.localStorage.removeItem("token");
        window.location.reload();
        window.location.href = "/";
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Nav
          token={token}
          LogOut={LogOut}
          userData={userData}
          setUserData={setUserData}
          getProfileData={getProfileData}
        />
        <Routes>
          <Route
            path="/"
            element={<Home token={token} getProfileData={getProfileData} LogOut={LogOut} expired={expired} setExpired={setExpired} userData={userData} LogIn={LogIn} />}
          ></Route>
          <Route path="/cat" element={<Results token={token} expired={expired} setExpired={setExpired} />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/about" element={<About />}></Route>
        </Routes>
        <Footer />
        <Analytics />
      </header>
    </div>
  );
}

export default App;
