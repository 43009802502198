import React, { useState } from 'react'
import RenderTopArtists from './RenderTopArtists'

function ScreenshotModal({ petContainerRef, bgImg, bgList, randomBgNum, catT, catM, catB, catOutline, catImg, showURL, catLogo, topArtists, spotifyLogo, petModal, setPetModal, downloadButton }) {
    const [artistDiv, toggleArtists] = useState(true);
    return (
        <div className='offscreen'>
                <div className="pet__object pet__object--download " ref={petContainerRef} style={{ backgroundImage: `url(${bgImg ? bgImg : bgList[randomBgNum]})` }} >
                    <h4 className="site__url"><img src={catLogo} draggable="false" className="site__img" alt="" />catify.site</h4>
                    <div>
                    <img className="pet__img pet__img--t pet__img--download" draggable="false" id="pet__object" src={catT} alt="" />
                    <img className="pet__img pet__img--m pet__img--download" draggable="false" src={catM} alt="" />
                    <img className="pet__img pet__img--b pet__img--download" draggable="false" src={catB} alt="" />
                    <img className="pet__img pet__img--outline pet__img--download" draggable="false" src={catOutline} alt="" />
                    <img className="pet__img--background pet__img--download pet__img--background--download" draggable="false" src={catImg} alt="" />
                    </div>
                    {artistDiv && <div className="artists__wrapper artists__wrapper--download">
                        <h3 className="following__artists">Inspired by top artists:</h3>
                        <RenderTopArtists artists={topArtists} />
                    </div>}
                    <img className="spotify__pet spotify__pet--download" src={spotifyLogo} alt="" />
                    </div>
            </div>
    )
}

export default ScreenshotModal