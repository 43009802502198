import React, { useEffect, useState } from "react";
import "../About.css";
import { Link, useNavigate } from "react-router-dom";
import flow from "../assets/flow.webp";

function About() {
  const [mobile, setMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {

    const handleResize = () => {
      // Update the state with the new window width
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    if (windowWidth <= 1025) {
      setMobile(true);

    }
    else {
      setMobile(false);
      navigate('/');
    }
  })

  return (
    <>
      <section id="about">

        <div className="about__container">
          <h2>About Catify</h2>

          <p>designed by <Link to="https://github.com/AWARD220">Alex</Link> and <Link to="https://github.com/WillDoyle">Will</Link></p>
          <div className="about__info">

            <br />

            <p>Catify is a dynamic virtual pet webapp that utilizes Spotify Web API to create a companion based on music interests.</p>
            <br />
            <p>Your top <strong>3</strong> spotify artists are sorted by listening hours. Each artist is assigned a generalised genre from a list of over 1386 unique genres. Each artist and their respective genre represents a section of the cat (top, middle, bottom, ascending order).</p>
            <img src={flow} className="flow__img" alt="" />
            <h3>A composite image is made from your chosen colour, background and your top 3 artist's using their corresponding genre.</h3>
            <br />
            <p>Sometimes an artist's genre can't be found due to limitations in our list. Not every artist's genre can result in a general genre. Any genres not found are automatically flagged by our system.</p>
            <p>&copy; 2024 Catify</p>
          </div>

        </div>
      </section>
    </>
  );
}

export default About;
